@import "../../../styles/TextStyles.scss";

.client-profile {
  margin: auto !important;
  padding: unset !important;
  .personal-detail {
    .custom-card {
      padding: 35px;
      border-radius: 7px;
      text-align: center;
      img {
        width: 143px;
        height: 143px;
        border-radius: 100%;
        object-fit: cover;
      }
      .name {
        margin-top: 26px;
        font-family: $font-bold;
        font-size: 26px;
      }
      .primary-button-container {
        width: 60%;
        margin: 15px auto;
      }
      hr {
        border: none;
        border-top: 1px solid #959595;
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0.2;
      }
      .filled-data {
        margin-top: 30px;
        text-align: left;
        label {
          font-size: 18px;
          font-family: $font-semibold;
          color: #959595;
          margin: 0;
          margin-top: 26px;
        }

        .response {
          font-size: 15px;
          font-family: $font-semibold;
          color: #000;
        }
      }
    }
    .back-arrow {
      float: left;
      .back {
        cursor: pointer !important;
        height: 26px;
        width: 26px;
      }
    }
  }
  .sar-section {
    padding: 25px 60px;
    border-radius: 7px;
    .title {
      font-family: $font-bold;
      font-size: 19px;
    }
    .value {
      font-size: 13px;
      font-family: $font-semibold;
      color: #959595;
    }
  }

  .past-section {
    margin-top: 27px;
    border-radius: 7px;
    .cta {
      padding: 12px 30px;
      border-bottom: 1px solid #9f9f9f29;
    }
    Table {
      font-size: 15px;
      color: #101928;
      font-weight: 700;
      tr {
        width: 100%;
        td {
          text-align: center;
          padding: 10px 5px;
        }
        th {
          padding-left: 1.75rem !important;
          padding-right: 1.75rem !important;
        }
      }
      tbody {
        .invoice-row {
          cursor: pointer;
        }
      }
    }

    .invoice-total {
      text-align: center;
    }
    .heading {
      font-family: $font-bold;
      font-size: 19px;
      padding: 12px 30px;
      border-bottom: 1px solid #9f9f9f29;
    }
    ul {
      list-style: none;
      max-height: 400px;
      overflow-y: auto;
      padding-bottom: 10px;
      li {
        cursor: pointer;
        padding: 15px 30px;
        border-bottom: 1px solid #9f9f9f29;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        .timing {
          margin-right: 20px;
          .date {
            font-size: 17px;
            font-family: $font-bold;
          }
          .month {
            font-size: 16px;
            font-family: $font-semibold;
            color: #959595;
          }
        }
        .name {
          width: 100%;
          margin-right: 20px;
          .slot {
            font-size: 16px;
            font-family: $font-semibold;
            color: #959595;
            .status {
              font-size: 12px;
              margin-left: 12px;
              text-transform: uppercase;
              &.confirmed {
                color: #0b586d;
              }
              &.completed {
                color: #a1a1a1;
              }
              &.started {
                color: #25c187;
              }
            }
          }
          .what {
            font-size: 17px;
            font-family: $font-bold;
          }
          .with {
            font-size: 16px;
            font-family: $font-semibold;
            color: #959595;
          }
        }
        .sar {
          align-self: center;
          font-size: 17px;
          font-family: $font-bold;
        }
      }
    }
  }
}
