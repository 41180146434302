@import "../../../styles/TextStyles.scss";
.custom-input {
  margin-bottom: 24px;
  label {
    font-family: $font-bold;
    font-size: 15px;
    color: #1d1d1d;
    margin-bottom: 0px;
  }
  .input-group {
    margin-top: 13px;
    .form-control {
      height: 57px;
      padding: 20px 10px;
      color: #000;
      border-radius: 5px;
      box-shadow: 0px 3px 6px #8282821a;
      font-size: 15px;
      &:focus,
      &:hover {
        border-color: #0b586d;
      }
    }
    img {
      position: absolute;
      bottom: 35%;
      right: 2%;
      cursor: pointer;
      width: 18px;
    }
  }
}
