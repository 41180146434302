.incomplete-profile-partners {
  padding-top: 30px;
  .heading-row {
    .custom-dropdown {
      .btn {
        height: 57px;
      }
    }
    .cta-col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-right: 15px;
      @media screen and (max-width: 480px) {
        margin-top: 20px;
      }
      .primary-button-container {
        width: 142px;
        border-radius: 7px;
      }
    }
  }
}
