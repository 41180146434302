.user-types-table {
  .table-wrapper {
    .title {
      font-size: 20px;
      font-family: "Nunito Sans";
    }
  }
  .otherWrap {
    img {
      margin-left: 10px;
    }
  }
}
