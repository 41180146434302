@font-face {
  font-family: "Nunito Sans";
  src: url("../assets/fonts/NunitoSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans-semibold";
  src: url("../assets/fonts/NunitoSans-SemiBold.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans-bold";
  src: url("../assets/fonts/NunitoSans-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Nunito Sans-extrabold";
  src: url("../assets/fonts/NunitoSans-ExtraBold.ttf");
  font-weight: bolder;
  font-style: normal;
}
html,
body {
  color: #000000;
  background-color: #f6f6f6;
  font-family: "Nunito Sans";
}

*,
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

.Toastify__toast-container {
  width: unset;
  z-index: 100000;
}

.Toastify__toast {
  min-height: unset;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Nunito Sans";
  font-style: italic;

  &.Toastify__toast--error {
    box-shadow: 0 4px 12px -3px #ffbbbb;
    background-color: #f03838;
  }
  &.Toastify__toast--warning {
    box-shadow: 0 4px 12px -3px rgba(231, 178, 84, 0.6);
    background-color: rgb(231, 178, 84);
  }
  &.Toastify__toast--success {
    box-shadow: 0 4px 12px -3px rgba(84, 231, 177, 0.6);
    background-color: rgb(71, 223, 167);
  }
}

.Toastify__close-button {
  margin: 0 0px 0 10px;
  padding: 0px;
}

// Modal css
.modal-content {
  border-radius: 7px;
  .modal-header {
    padding: 21px 32px;
    .modal-title {
      font-size: 19px;
      font-family: "Nunito Sans-bold";
      small {
        color: #818181;
        font-family: "Nunito Sans-semibold";
        font-size: 14px;
      }
    }
  }

  .modal-body {
    padding: 21px 32px;
  }
  .modal-footer {
    padding: 21px 32px;
    border: none;
    .secondary-button-container {
      margin-right: 25px;
    }
  }
}



// Table style
.table {
  color: #000000;
  thead {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    tr {
      th {
        border: none;
        padding: 20px 20px;
        font-size: 14px;
        font-family: "Nunito Sans-bold";
      }
    }
  }
  tbody {
    tr {
      border-top: 10px solid #f6f6f6;

      td {
        border: none;
        padding: 20px 20px;
        font-size: 14px;
        background: #fff;
        font-family: "Nunito Sans-semibold";
        vertical-align: middle;
      }
    }
  }
}
