@import "../../styles/TextStyles.scss";
.dashboard {
  .page-name {
    padding: 30px 0px;
    font-size: 19px;
    font-family: $font-bold;
    small {
      font-size: 13px;
    }
  }
  .middle-block {
    margin-top: 30px;
    height: 100%;

    .appointment {
      .custom-card {
        background-color: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px #9f9f9f29;
        border-radius: 7px;
        padding: 25px 30px;
        height: 100%;
        .upocoming-appointments-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          img {
            cursor: pointer;
          }
        }
        h3 {
          margin-bottom: 10px;
          font-family: $font-bold;
          font-size: 22px;
        }
        ul {
          list-style: none;
          li {
            margin-top: 20px;
            .top-deck {
              .left {
                font-family: $font-semibold;
                font-size: 17px;
              }
              .right {
                font-size: 12px;
                color: #b5b5b5;
                font-family: $font-bold;
                text-align: right;
              }
            }
            .bottom-deck {
              .left {
                font-family: $font-regular;
                font-size: 14px;
                color: #959595;
              }
              .right {
                color: #000000;
                font-family: 17px;
                font-family: $font-bold;
                text-align: right;
              }
            }
          }
        }
        .no-data-container {
          text-align: center;
          padding: 15px 10px;
          width: 100%;
          height: calc(100% - 86px);
          display: flex;
          flex-direction: column;
          img {
            width: inherit;
            margin: auto;
          }
          h5 {
            margin-top: 20px;
            color: #ffffff;
          }
        }
      }
    }
    .no-upcoming-appointments {
      .custom-card {
        background: rgb(11, 88, 109) 0% 0% no-repeat padding-box !important;
        h3 {
          color: #ffffff;
        }
      }
    }
    .service-summary {
      .no-data-container {
        text-align: center;
        padding: 15px 10px;
        width: 100%;
        height: calc(100% - 86px);
        display: flex;
        flex-direction: column;
        img {
          width: inherit;
          margin: auto;
        }
        h5 {
          margin-top: 20px;
          color: #ffffff;
        }
      }
      .custom-card {
        height: 100%;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px #9f9f9f29;
        border-radius: 7px;
        padding: 25px 30px;
        h3 {
          margin-bottom: 10px;
          font-family: $font-bold;
          font-size: 22px;
        }
        .chart {
          //  max-height: 400px;
          margin: 10px auto;
        }
      }
    }
  }
  .bottom-block {
    margin-top: 30px;
    min-height: 400px;
    .bar-chart {
      // min-height: 400px;
      .custom-card {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px #9f9f9f29;
        border-radius: 7px;
        padding: 25px 30px;
        h3 {
          margin-bottom: 10px;
          font-family: $font-bold;
          font-size: 22px;
        }
      }
      .header-cont{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        .date-cont{
          height: 40px;
          .react-datepicker-wrapper{
            width: 100%;
            height: 40px;
            padding: 3px 10px 0px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #D4D4D4;
            border-radius: 4px;
            .example-custom-input{
              padding: unset;
              width: 100% !important;
              margin-left: 0 !important;
              white-space: nowrap ;
              font-size: 19px;
              font-weight: normal;
              color: grey;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              @media (max-width: 992px) {
                font-size: 15px;
              }
            }
            &::before{
              display: none;
            }
            &:hover{
              border: 1px solid #b9b6b6;
            }
          }
        }
        @media (max-width: 700px) {
          flex-direction: column;          
          align-items: flex-start;
        }
      }
      
    }
  }
}
.upocoming-appointment-filter {
  background-color: #ffffff;
  .popover-body {
    min-width: 300px;
    .custom-dropdown button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .button-wrapper {
      margin: 10px;
      display: flex;
      justify-content: space-around;
      .apply {
        background-color: #161f2a;
      }
      .close {
        background-color: #ffffff;
        color: #000000;
        border: 1px solid #f7f7f7;
        box-shadow: 0 0 0 0.2rem #f7f7f7 !important;
      }
      button {
        min-width: 100px;
        font-size: 14px;
        border-color: unset !important;
        box-shadow: unset;
        &:disabled {
          background-color: #d5d6da;
        }
        &:active {
          background-color: unset;
        }
      }
    }
  }
}
