.services-container {
  .table-wrapper {
    .search-wrap {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .custom-input-search {
        padding: 10px;
        padding-left: unset !important;
      }
      .cta-col {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-right: 28px;
        @media screen and (max-width: 480px) {
          margin-top: 15px !important;
        }
        .primary-button-container {
          width: 142px;
          border-radius: 7px;
        }
      }
    }
  }
}
.edit-service-container {
  th {
    &:last-child {
      text-align: end;
    }
  }
  td {
    .otherWrap {
      img {
        &:not(first-child) {
          margin-left: 10px;
        }
      }
    }
  }
}
.service-form-container {
  .css-6iiga6-container {
    height: 57px;
  }
  .footer {
    .save-btn {
      background-color: #0b586d;
    }
  }
}
.react-bootstrap-table {
  tbody {
    tr {
      border-top: 4px solid #f6f6f6 !important;
    }
  }
}
.table-responsive {
  tbody {
    tr {
      border-top: 4px solid #f6f6f6 !important;
    }
  }
}
