.global-settings-container {
  margin-top: 25px;
  padding-inline: unset;
  .card-container {
    padding: 20px;
    .container {
      padding-right: unset;
      padding-left: unset;
      @media screen and (max-width: 768px) {
      }
    }
    .setting-cards {
      margin-bottom: 3px;
      margin-top: unset;
      .card-header {
        padding: unset;
        background-color: unset;
        border-bottom: unset;
        img {
          margin-right: 3px;
        }
      }
      .btn-link {
        text-decoration: unset;
        text-align: left;
        color: #333;
        padding: unset;
        &:hover {
          color: #72afd2;
        }
        &:focus {
          outline: unset;
          box-shadow: unset;
        }
      }
    }
  }
  .cards {
    background-color: rgba(0, 0, 0, 0.03);
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    .input-row {
      .col-md-6 {
        margin-bottom: 15px;
        @media screen and (max-width: 768px) {
          flex: unset;
          max-width: 100%;
        }
      }
    }
  }
  .save-btn {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    .btn {
      background-color: #0b586d;
    }
  }
}
