.staff {
  margin-top: 30px;
  // padding: unset !important;
  // @media screen and (max-width: 480px) {
  //   padding: unset;
  // }
  .buttons-group {
    .rounded-button {
      width: 162px;
      height: 35px;
      border-radius: 491px;
      text-align: center;
    }
  }
}
