.categories {
  margin-top: 10px;
  max-width: 100%;
  .table-wrapper {
    .profile-pic {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      object-fit: cover;
      object-position: center;
      margin-right: 20px;
    }
  }
}
.footer {
  .save-btn {
    background-color: #0b586d;
  }
}
.image-wrapper{
  margin-top: 20px;
  padding: 0;
  img{
    margin-top: 20px;
    width: 256px;
    height: 256px;
  }
}


.add-fav-salon-cont{
  .dropdown-menu{
    max-height: 300px !important;
    overflow-y: scroll !important;
    width: 100%;
  }
}
