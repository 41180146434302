.keywords-table {
  margin-top: 10px;
  .table-wrapper {
    .search-wrap {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .custom-input-search {
        padding: 10px;
      }
      .cta-col {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-right: 16px;
        @media screen and (max-width: 480px) {
          margin-top: 15px !important;
        }
        .primary-button-container {
          width: 142px;
          border-radius: 7px;
        }
      }
    }

    .table td {
      .otherWrap {
        .edit {
          margin-right: 10px;
        }
      }
    }
  }
}
.footer {
  .save-btn {
    background-color: #0b586d;
  }
}
