@import "../../../styles/TextStyles.scss";
body {
  overflow-y: auto;
}
.complains-table {
  .table {
    tr {
      cursor: pointer;
      th {
        padding: 10px;
        vertical-align: middle;
      }
      td {
        padding: 14px;
        color: #5d5d5d;
        .status-formatter {
          padding: 4px 8px;
          border-radius: 8px;
          text-align: center;
          max-width: 100px;
          font-size: 16px;
          font-family: $font-extrabold;
        }
      }
    }
  }
  .spinner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-left: 10px;
    }
  }

  .pagination {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .result-text {
      color: #6d6d6d;
      font-size: 15px;
    }
    .count {
      display: flex;
      width: 100%;
      cursor: pointer;
      justify-content: flex-end;
      .previous,
      .next {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #d9d9d9;
        border-radius: 100%;
      }
      .previous {
        margin-right: 20px;
      }
      .next {
        margin-left: 20px;
      }
      .pages {
        height: 40px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #d9d9d9;
        border-radius: 30px;
        font-family: $font-semibold;
        font-size: 14px;
        color: #989898;
        padding: 6px 15px;

        justify-content: space-evenly;
        span {
          vertical-align: middle;
          padding: 11px 10px;
          &.active {
            color: #ffffff;
            background: #0b586d;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
