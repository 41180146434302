
@import "../../../styles/TextStyles.scss";

.vouchers {

  // padding: unset !important;
  @media screen and (max-width: 480px) {
    // padding: unset;
  }

  // margin-top: 30px;

  .heading-row {
    justify-content: space-between;

    .custom-input-search {
      padding: 10px;

      .input-group {
        border-radius: 154px;
        height: 41px;

        .input-group-text {
          padding-top: unset;
        }
      }
    }

    .heading-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

      .seller-name{
        font-family: $font-bold;
        font-size: 20px;
      }

    .cta-col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      // padding-right: 28px;

      @media screen and (max-width: 480px) {
        margin-top: 15px !important;
      }

      .primary-button-container {
        width: 142px;
        border-radius: 7px;
      }
    }
  }

  .spinner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }

  .no-data-text {
    text-align: center;
  }

  .image-wrapper{
    margin-top: 20px;
    padding: 10px;
    img{
      width: 100%;
      height: 300px;
    }
  }
}