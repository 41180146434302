.main-side {
  height: 100vh;
  overflow: hidden;
  padding-right: 5px !important;
  @media screen and (max-width: 480px) {
    // height: auto;
    // padding: unset;
  }
  .main-container {
    height: calc(100% - 70px);
    @media screen and (max-width: 480px) {
      // height: calc(100vh - 100px);
    }
    // height:100%;//remove it in production
    overflow: auto;
    padding-bottom: 30px;
  }
  
  .main-container::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .main-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  
  /* Handle */
  .main-container::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
  }

  /* Handle on hover */
  .main-container::-webkit-scrollbar-thumb:hover {
    background: grey; 
  }
}
