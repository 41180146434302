@import "../../../styles/TextStyles.scss";

.expansionReport {
  .no-data-text {
    text-align: center;
  }

  .filtersCont {
    margin: 0;

    .primary-button-container {
      margin-top: 40px !important;

      @media (max-width: 576px) {
        margin-top: 1px !important;
      }
    }
  }

  .mainContent {
    margin: 0px;
    margin-top: 20px;

    .topRowCont {
      .headingCont {
        margin-right: 25px;
        height: 80px;
        background-color: #dadada;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;

        p {
          padding-left: 30px;
          font-size: 30px;
          font-family: $font-extrabold;

          @media (max-width: 992px) {
            font-size: 20px;
            font-family: $font-extrabold;
          }
        }

        @media (max-width: 768px) {
          margin-bottom: 15px;
          margin-right: 0px;
        }
      }

      .totalCont {
        padding: 0;
        height: 80px;
        background-color: #3A5F71;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px 0px 20px;
        color: white;
        border-radius: 10px;

        .num {
          font-size: 30px;
          font-family: $font-extrabold;

          @media (max-width: 992px) {
            font-size: 20px;
          }
        }

        .text {
          font-size: 15px;
          font-family: $font-extrabold;
          padding-left: 15px;
        }

        @media (max-width: 768px) {
          margin-bottom: 15px;
        }
      }

      .freelancer {
        background-color: #8D7551;
      }
    }

    .cards-cont {
      margin: 0;

      .cards-content {
        // display: flex;
        // justify-content: space-evenly;
        // align-items: center;
        // flex-wrap: nowrap;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        @media (max-width: 992px) {
          grid-template-columns: repeat(3, minmax(0px, 1fr));
        }
        @media (max-width: 768px) {
          grid-template-columns: repeat(2, minmax(0px, 1fr));
        }
        @media (max-width: 576px) {
          grid-template-columns: repeat(1, minmax(0px, 1fr));
        }

        .card-cont {
          padding: 10px;
          // width: fit-content;
          width: 100%;
          @media (max-width: 576px){
          width: 100%;
          }

          .card-content {
            .header-cont {
              background-color: #3A5F71;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 50px;
              border-radius: 10px;


              p {
                color: white;
                font-size: 20px;
                font-family: $font-bold
              }
            }

            .graphs-cont {
              background-color: #DADADA;
              margin-top: 10px;
              width: 100%;
              border-radius: 10px;

              .graphs-content {
                .bar-graph-cont {
                  width: 100%;
                  height: 200px;
                  padding: 10px;

                  canvas {
                    width: inherit;
                  }
                }

                .graph-legend-cont {
                  margin-top: -90px;
                  padding: 0px 30px;

                  .partners-heading {
                    font-size: 14px;
                    font-family: $font-bold;
                    text-align: center;
                  }

                  .graph-legend-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                      font-size: 14px;
                      font-family: $font-bold
                    }

                    .left-cont {
                      display: flex;
                      align-items: center;

                      span {
                        width: 10px;
                        height: 10px;
                        display: block;
                        margin-right: 10px;
                      }

                      .active {
                        background-color: #a5aeb5;
                      }

                      .in-active {
                        background-color: #3a5f71;
                      }
                    }

                    .right-cont {}
                  }
                }

                .trans-graph-cont {
                  margin-top: 10px;

                  .trans-heading {
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #7F7F7F;

                    p {
                      color: white;
                      font-size: 20px;
                      font-family: $font-bold
                    }
                  }

                  .pie-chart-cont {
                    width: 100%;
                    height: 200px;
                    padding: 10px;

                    canvas {
                      width: inherit;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}