@import "../../styles/TextStyles.scss";
.all-service {
  margin-top: 30px;
  .edit-btn {
    justify-content: flex-end;
    align-items: center;
    .dropdown {
      .dropdown-toggle::after {
        position: unset !important;
        margin-left: 10px !important;        
      }
      @media screen and (max-width: 540px) {
        padding-right: 15px;
      }
    }
    .d-flex {
      @media screen and (max-width: 540px) {
        margin-top: 15px;
        justify-content: flex-end;
      }
    }
    .more-option {
      padding: 0 15px;
      min-width: 200px;
      button {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .top-cta-row {
    justify-content: space-between;
    .rounded-button {
      margin-bottom: 0px;
    }
    .cta-group {
      display: flex;
      flex-direction: row;
      .secondary-button-container {
        margin-right: 17.5px;
      }
    }
  }
  .service-list {
    margin-top: 80px;
    cursor: pointer;

    ul {
      list-style: none;
      li {
        .row {
          padding: 15px 20px;
          border-bottom: 1px solid #8282821a;
          justify-content: space-between;
          align-items: center;
          .name {
            font-size: 22px;
            font-family: $font-bold;
          }
          .icon {
            width: 12px;
            height: auto;
          }
        }
        .detailed {
          padding: 15px 20px;
          align-items: center;
          background: #ffffff;
          .name {
            font-size: 15px;
            font-family: $font-semibold;
          }
          .duration {
            font-size: 13px;
            font-family: $font-regular;
            text-align: center;
            color: #959595;
          }
          .amount {
            font-size: 17px;
            font-family: $font-bold;
            text-align: right;
          }
        }
      }
    }
  }
  .admin-cards {
    margin-bottom: 5px;
    margin-top: unset;
    .card-header {
      padding: unset;
      background-color: unset;
      border: unset;
      outline: unset;
    }
    .show {
      border-top: 1px solid #c7c7c7;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .btn-link {
      text-decoration: unset;
      text-align: left;
      font-size: large;
      color: #333;
      padding: unset;
      &:hover {
        color: rgba(51, 51, 51, 0.712);
      }
      &:focus {
        outline: 0;
        box-shadow: unset;
      }
    }
    .add-btn {
      @media screen and (max-width: 540px) {
        margin-top: 15px;
      }
      .add-new-Admin {
        @media (min-width: 768px) and (max-width: 768px) {
          padding: 3px 30px 14px;
        }
      }
    }
  }
}
