

.bookings{
  .search-wrap{
    height: auto;
  }
  .react-bootstrap-table{
    overflow-x: auto;
  }
  .table-wrapper{
    padding: 0px 10px;
  }
}
.addressWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  img {
    margin-right: 5px;
  }
}
.iconsWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  img {
    max-width: 16px;
    cursor: pointer;
    &:not(last-child) {
      margin-right: 4px;
    }
  }
}
.driverIcon {
  img {
    width: 20px;
    cursor: pointer;
  }
}
