@import "../../../styles/TextStyles.scss";

.reportsTabsCont {

  .nav {
    // border: 1px solid #c5c5c5 !important;
    flex-wrap: nowrap !important;
    width: 100%;
    overflow-x: scroll;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      display: none;
    }
     
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(169, 169, 169, 0.3);
    }
     
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }

    a {
      border: 1px solid #c5c5c5 !important;
      color: black;
      font-size: 15px;
      font-family: $font-extrabold;
      text-wrap: nowrap;
    }

    .nav-link.active {
      background-color: #0b586d !important;
    }
  }




}