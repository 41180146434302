
.service-area-cont{
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 0px;
  .App-map {
    height: calc(100vh - 180px);
    width: 100%;
    padding: 0px 0px 20px 0px;
    border: 1px solid #cdc9c9;
  }
  .overlay-cont{
    .del-fence-btn{
      width: 10px;
      height: 10px;
      float: right;
      cursor: pointer;
    }
  }
}
