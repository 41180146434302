.profile-completion-details-cont {

	.profile-completion-body {
		.profile-completion-body-cont {

			.profile-completion-header {
				padding-bottom: 20px;

				p {
					font: normal normal normal 20px/20px Nunito Sans;
					letter-spacing: 0px;
					color: #000000;
					text-transform: capitalize;
					opacity: 0.56;
				}
			}

			.top-status-bar-cont {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 10px;

				.top-bar-step-1 {
					img {
						width: 50px;
						height: 50px;
					}
				}

				.bars-container {
					flex-grow: 1;
					display: flex;
					justify-content: space-evenly;
					align-items: center;

					.top-bar-step-2 {
						// width: 207px;
						flex-grow: 1;
						height: 18px;
						background: #ECE5DF 0% 0% no-repeat padding-box;
						opacity: 1;
					}

					.top-bar-step-3 {
						// width: 207px;
						flex-grow: 1;
						height: 18px;
						background: #D9C7B4 0% 0% no-repeat padding-box;
						opacity: 1;
					}

					.top-bar-step-4 {
						// width: 207px;
						flex-grow: 1;
						height: 18px;
						background: #D2B599 0% 0% no-repeat padding-box;
						opacity: 1;
					}

					.top-bar-step-5 {
						// width: 207px;
						flex-grow: 1;
						height: 18px;
						background: #C8C8C8 0% 0% no-repeat padding-box;
						opacity: 1;
					}

					.current-bar-status-pic {
						width: 40px;
						height: 40px;
						float: right;
						margin-top: -10px;
						margin-right: -3px;
					}

					.completed-bar-color {}

					.incomplete-bar-color {
						background: #C8C8C8 0% 0% no-repeat padding-box !important;
					}
				}

				.top-bar-step-6 {
					img {
						width: 50px;
						height: 50px;
					}
				}
			}

			.steps-cont {
				display: flex;
				justify-content: space-between;
				padding-top: 10px;

				.left-sec {
					width: 80px;
					display: flex;
					justify-content: center;
					align-items: center;

					p {
						font: normal normal bold 22px/18px Nunito Sans;
						letter-spacing: 0px;
						color: #000000;
						text-transform: capitalize;
						opacity: 1;
					}

					.incompleted {
						font: normal normal bold 22px/18px Nunito Sans;
						letter-spacing: 0px;
						color: #000000;
						text-transform: capitalize;
						opacity: 0.19;
					}
				}

				.middle-sec {
					flex-grow: 1;

					.middle-sec-cont {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.desc-img {
							margin: 0px 20px;
							width: 45px;
							height: 45px;
						}

						.desc-cont {
							flex-grow: 1;
							padding: 5px;

							.desc-header {
								font: normal normal bold 15px/20px Nunito Sans;
								letter-spacing: 0px;
								color: #000000;
								text-transform: capitalize;
								opacity: 1;
							}

							.desc-desc-desc {
								font: normal normal normal 14px/15px Nunito Sans;
								letter-spacing: 0px;
								color: #000000;
								opacity: 0.68;
							}
						}
					}
				}

				.right-sec {
					width: 50px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}

			}

		}
	}

	.profile-completion-footer {
		padding-top: 30px;

		.profile-completion-footer-cont {
			display: flex;
			justify-content: flex-end;

			.profile-completion-do-later {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				font: normal normal bold 20px/28px Nunito Sans;
				letter-spacing: 0px;
				color: #0B586D;
				opacity: 1;
			}

			.profile-completion-lets-do {
				display: flex;
				justify-content: center;
				align-items: center;
				background: #0B586D 0% 0% no-repeat padding-box;
				box-shadow: 0px 3px 6px #9E9E9E29;
				border-radius: 5px;
				opacity: 1;
				padding: 10px 20px;
				cursor: pointer;

				p {
					font: normal normal medium 15px/23px Poppins;
					letter-spacing: 0px;
					color: #FFFFFF;
				}
			}
		}
	}
}