@import "../../../styles/TextStyles.scss";
.add-member {
  .choice-section {
    margin-top: 50px;
    .title {
      font-size: 19px;
      font-family: $font-bold;
    }
    .choice-card-row {
      margin-top: 40px;
      text-align: center;
      .custom-card {
        padding: 50px;
        box-shadow: 0px 1px 5px #9f9f9f29;
        border-radius: 7px;
        img {
          width: 25px;
          height: auto;
        }
        .way {
          margin-top: 30px;
          font-family: $font-bold;
          font-size: 19px;
        }
        .dis {
          margin-top: 45px;
          font-size: 15px;
          color: #858585;
          font-family: $font-semibold;
        }
      }
    }

    .member-info-row {
      margin-top: 30px;
      .member-pic {
        @media (min-width: 1024px) and (max-width: 1024px) {
          padding-left: unset;
        }
      }
      .basic-info {
        .profile-pic-label {
          position: relative;
          width: 120px;
          height: 120px;
          border-radius: 100%;
          &:hover {
            .overlay {
              opacity: 1;
            }
          }
          .profile-pic {
            position: absolute;
            width: 120px;
            height: 120px;
            border-radius: 100%;
            object-fit: cover;
            padding: 2px;
            border: 1px solid #0b586d;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(#0b586d, 0.5);
            border-radius: 100%;
            opacity: 0;
          }
          input {
            visibility: hidden;
            display: none;
          }
        }
      }
      .work-at {
        .image-object {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;
          img {
            width: 91px;
            height: 91px;
            object-fit: cover;
            border-radius: 100%;
            margin-right: 25px;
          }
          .content {
            .caption {
              font-size: 17px;
              font-family: $font-bold;
            }
            .desc {
              font-size: 15px;
              color: #858585;
            }
          }
        }
      }
      .booking {
        .image-object {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;
          .checkbox {
            margin-right: 25px;
          }

          .content {
            .caption {
              font-size: 17px;
              font-family: $font-bold;
            }
            .desc {
              font-size: 15px;
              color: #858585;
            }
          }
        }
      }
      .color {
        .label {
          font-size: 17px;
        }

        .ball-cont {
          margin-top: 17px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .color-ball {
            width: 44px;
            height: 44px;
            border-radius: 100%;

            margin-right: 22px;
            margin-bottom: 5px;
            border: 2px solid transparent;
            &.active {
              border-color: #c7c7c7;
            }
          }
        }
      }
    }
  }
  .services {
    display: inline-flex;
    padding-left: 0px !important;
    .custom-input {
      input {
        text-overflow: ellipsis;
      }
    }
    .otp-input {
      display: flex;
      align-items: center;
      .custom-input {
        margin-bottom: 0;
        .input-group {
          margin-top: 0;
        }
      }
      button {
        margin-left: 10px;
      }
    }
  }
}

.staff-select-service-popup {
  .modal-header {
    padding: 21px 32px;
    .modal-title {
      font-size: 19px;
      font-family: $font-bold;
    }
  }

  .modal-body {
    padding: 0;
    .search-input {
      margin: 20px 40px;
    }
    ul {
      list-style: none;
      .no-service-container {
        text-align: center;
      }
      li {
        padding: 20px 40px;
        border-top: 1px solid #d6d6d6;

        label {
          font-size: 19px;
          font-family: $font-bold;
          input {
            margin-right: 10px;
            height: 20px;
            width: 20px;
            vertical-align: middle;
          }
          small {
            font-size: 15px;
            color: #929292;
            font-family: $font-semibold;
            &.second {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
  .modal-footer {
    padding: 21px 32px;
    border: none;
    .secondary-button-container {
      margin-right: 25px;
    }
  }
}

.react-datepicker {
  background-color: #ffffff;
  border: unset;
  box-shadow: 2px 3px 4px 6px rgba(#545e61, 0.13);
  .react-datepicker__header {
    background-color: white;
    border-bottom: unset;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 1.3rem;
    background-color: #000000;
    color: #fff;
  }
  .react-datepicker__day--selected {
    background-color: black;
    border-radius: 1.3rem;
  }
  .react-datepicker__day--in-range {
    border-radius: 1.3rem;
    background-color: #000000;
    color: #fff;
  }
}
