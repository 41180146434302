.delivery-form-container {
  margin-top: 30px;
  .custom-input .input-group .form-control {
    height: 57px;
    width: 258px;
    padding: 20px 10px;
    color: #000;
    border-radius: 5px;
    box-shadow: 0px 5px 6px #8282821a;
    font-size: 15px;
    border: 1px solid #D9D9D9;
  }
  .heading {
    font-weight: bold;
    color: #121111;
    position: unset;
    top: unset;
  }
  h5 {
    position: relative;
    top: 44%;
  }
  h4 {
    font-weight: 600;
  }
  .save-btn {
    justify-content: flex-end;
    margin-right: unset;
    margin-top: 30px;
  }
}
