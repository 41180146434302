@import "../../../styles/TextStyles.scss";

.add-service {
  .step-one {
    margin-top: 200px;
    .heading {
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 19px;
        font-family: $font-bold;
      }
      .step-count {
        font-size: 19px;
        color: #747474;
      }
    }
    .custom-card {
      border-radius: 7px;
      box-shadow: 0px 1px 5px #9f9f9f29;
      padding: 20px 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 30px;
      &:hover {
        box-shadow: 0px 6px 9px #63636329;
      }
      .illu {
        width: 22px;
        height: 31px;
        margin-right: 16px;
      }
      .details {
        width: 100%;
        margin-right: 16px;
        .name {
          font-size: 19px;
          font-family: $font-bold;
        }
        .disc {
          font-size: 15px;
          font-family: $font-bold;
          color: #858585;
        }
      }
      .arrow {
        width: 9px;
        height: 15px;
      }
    }
  }

  .step-two {
    margin-top: 200px;
    .heading {
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 19px;
        font-family: $font-bold;
      }
      .step-count {
        font-size: 19px;
        color: #747474;
      }
    }
    .card-row {
      margin-top: 30px;
      justify-content: center;
      .custom-card {
        text-align: center;
        border-radius: 7px;
        box-shadow: 0px 1px 5px #9f9f9f29;
        padding: 100px 55px;
        .title {
          font-size: 19px;
          font-family: $font-bold;
        }
        .subtitle {
          color: #858585;
          font-size: 15px;
          font-family: $font-semibold;
        }
      }
    }
  }
  .step-three {
    margin-top: 50px;
    .heading {
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 19px;
        font-family: $font-bold;
      }
      .step-count {
        font-size: 19px;
        color: #747474;
      }
    }
  }
}
