@import "../../styles/TextStyles.scss";

.custom-dropdown {
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #ffffff;
    color: #6d6d6d;
    border: 1px solid #e6e6e6;
    box-shadow: none;
  }
  label {
    font-family: $font-bold;
    font-size: 15px;
    color: #1d1d1d;
    margin-bottom: 13px;
    .subtitle {
      font-size: 14px;
      color: #bebebe;
    }
  }

  button {
    width: 100%;
    padding: 17px 19px;
    text-align: left;
    font-size: 15px;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    color: #6d6d6d;
    text-transform: capitalize;
    &:hover,
    &:focus,
    &:active {
      background-color: #ffffff;
      color: #6d6d6d;
      border: 1px solid #e6e6e6;
      box-shadow: none;
    }
    &::after {
      margin-left: 10px;
      // bottom: 18px;
    }
    &:disabled {
      border-color: #e9ecef;
      background-color: #e9ecef;
      opacity: 1;
      color: black;
    }
  }
  .dropdown-menu {
    max-height: 300px;
    overflow-y: scroll;
    .dropdown-item {
      font-size: 15px;
      color: #000;
      &:active {
        background-color: #d4dde6;
      }
    }
  }
  img {
    height: 12px;
    position: absolute;
    width: 20px;
    top: 46%;
    right: 31px;
  }
  .custom-dropdown-select__indicator-separator{
    top: 15px !important;
  }
  .custom-option {
    color: #cd8599 !important;
  }
}
.from-calendar{
  button{
    padding: 8px 10px !important;
    &::after{
      bottom: 17px !important;
    }
  }
}
