.custom-input-search {
  padding: 24px;
  margin-bottom: 0;
  box-shadow:none;
  .input-group {
    max-width: 100%;
    background: #ffffff;
    box-shadow:none;
    border: 1px solid #e6e6e6;
    border-radius: 261px;
    overflow: hidden;
    padding: 5px 10px 5px 20px;
    margin-top: 0;

    input {
      border: none;
      font-size: 14px;
      padding: 0px 20px;
      &:focus,
      &:hover {
        box-shadow: none;
        border: none;
      }
    }
    .input-group-text {
      border: none;
      background-color: unset;
      img {
        width: 27px;
        height: auto;
      }
    }
    .form-control{
      box-shadow:none;
    }
  }
}
// .custom-input {
//   padding: 24px;
//   margin-bottom: 0;
//   .input-group {
//     margin-top: 0;
//   }
// }
