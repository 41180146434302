@import "../../styles/TextStyles.scss";

.service {
  margin-top: 25px;
  padding: unset !important;
  @media screen and (max-width: 480px) {
    padding: unset;
  }
  .top-cta-row {
    // margin-top: -5px;
    justify-content: space-between;

    .rounded-button {
      margin-bottom: 0px;
      margin-top: 15px;
      height: 35px;
    }
    .custom-input-search {
      padding: 10px;
      .input-group {
        border-radius: 154px;
      }
    }
    .cta-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-right: 28px;
      @media screen and (max-width: 480px) {
        margin-top: 15px;
      }
      .secondary-button-container {
        margin-right: 17.5px;
      }
    }
  }

  .service-list {
    // margin-top: 60px;
    padding: unset;
    cursor: pointer;

    ul {
      list-style: none;
      .cat-list {
        border-bottom: 1px solid #8282821a;
      }
      li {
        .row {
          padding: 15px 20px;
          justify-content: space-between;
          align-items: center;
          .name {
            font-size: 22px;
            font-family: $font-bold;
          }
          .icon {
            width: 12px;
            height: auto;
          }
        }
        .detailed {
          padding: 15px 20px;
          align-items: center;
          background: #ffffff;
          margin: 0;
          border: 1px solid #8282821a;
          .name {
            font-size: 15px;
            font-family: $font-semibold;
          }
          .duration {
            text-align: start !important;
            font-size: 13px;
            font-family: $font-regular;
            text-align: center;
            color: #959595;
          }
          .amount {
            font-size: 17px;
            font-family: $font-bold;
            text-align: right;
          }
          .danger-button-container {
            max-height: 45px;
            background-color: #ff000091;
            border-color: #ff000091;
            .button-text {
              font-size: 13px;
            }
          }
        }
      }
      .delete {
        text-align: end;
        position: absolute;
        top: 50%;
        right: 1%;
        transform: translateY(-50%);
        img {
          margin-left: 10px;
        }
      }
    }
  }
  .spinner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }
    span {
      margin-left: 10px;
    }
  }
}
