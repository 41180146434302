.reviews-contaiiner {
  .rating-panel {
    margin-top: 25px;
  }
  .left-rating-box {
    background-color: #fafafa;
    border: 1px solid #efefef;
    padding: 5px 5px 20px 10px;
    border-radius: 3px;
    text-align: center;
    margin-bottom: 20px;
    .btn {
      margin-right: 3px;
      margin-bottom: 3px;
    }
  }
  .right-rating-box {
    .progress-box {
      display: flex;
      @media (min-width: 540px) and (max-width: 540px) {
        justify-content: center;
      }
      .stars {
        display: flex;
        width: 35px;
        img {
          margin-left: 4px;
          margin-bottom: 3px;
          width: 13px;
        }
      }
      .progress-bar-row {
        min-width: 180px;
        background-color: unset;
        margin-top: 3px;
        margin-inline: 15px;
        @media (min-width: 540px) and (max-width: 540px) {
          min-width: 120px;
        }
      }
    }
    @media (min-width: 768px) and (max-width: 768px) {
      padding-left: unset;
    }
  }
  .rating-stars {
    .btn {
      margin-bottom: 3px;
      margin-right: 3px;
    }
  }
  .nav-link {
    color: #4731bfe0;
  }
  .search-wrap {
    justify-content: flex-end;
  }
  .no-data {
    margin-top: 20px;
    text-align: center;
    color: #696969;
  }
  .reviews-table {
    padding: unset;
    .react-bootstrap-table {
      overflow: auto;
    }
  }
}
