@import "../../styles/TextStyles.scss";

.driver-bt {
  width: 100%;
  margin-right: 20px;
  .add-new-driver {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0px 20px 0px;
  }
}
.supplier-driver-container {
  .table-wrapper {
    th {
      box-shadow: 0px 1px 5px #9f9f9f29;
      font-family:$font-bold;
      &:last-child {
        text-align: start;
      }
    }
    td {
      .otherWrap {
        .edit {
          width: 20px;
          height: 21px;
        }
        .delete {
          width: 21px;
          height: 22px;
        }
        img {
          &:not(first-child) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.phone-number-input {
  margin-bottom: unset;
}
.footer {
  justify-content: space-between;
}
