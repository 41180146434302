@import "../../styles/TextStyles.scss";
.top-menu {
  padding: 10px 10px 10px 15px;
  border-bottom: 1px solid lightgray;
  @media screen and (max-width: 480px) {
    padding-top: 5px;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: unset;
    .heading {
      font-size: 25px;
      font-family: $font-bold;
      flex-wrap: wrap;
      flex-direction: row;
      display: flex;
      align-items: center;
      @media screen and (max-width: 480px) {
        // margin-left: 50px;
        // padding: unset;
      }

      img {
        height: 25px;
        width: auto;
        margin-right: 15px;
        // @media screen and (max-width: 480px) {
        //   margin-left: 15px;
        // }
      }
      .calendar-button-wrap {
        margin-left: 10px;
        // padding: 10px;
        @media screen and (max-width: 480px) {
          padding-bottom: unset;
        }
        .calendar-buttons {
          font-size: 14px;
          font-family: $font-extrabold;
          color: #8f8f8f;
          padding: 7px 20px;
          border-radius: 24px !important;
          border: none !important;
          margin-left: 20px;
          background-color: transparent;
          font-size: 17px;
          font-family: $font-semibold;
          text-transform: capitalize;
          &.fc-button-active {
            background-color: #0b586d13 !important;
            color: #0b586d !important;
            font-size: 17px;
            font-family: $font-extrabold;
          }
          &:hover {
            color: #fff;
            background: #1e2b37;
          }
        }
        .active-btn {
          background-color: #0b586d13 !important;
          padding: 7px 20px;
          color: #0b586d !important;
        }
      }
    }

    .right-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and (max-width: 4370px) {
        flex-grow: 1;
        justify-content: flex-end;
      }
      .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        width: 40px;
        height: 40px;
        padding: 10px;
        background: #ffffff 0% 0% no-repeat padding-box;
        display: flex;
        color: rgba(0, 0, 0, 0.795);
        font-weight: bold;
        font-size: 17px;
        box-shadow: 0px 3px 6px #2e2e2e12;
        border: 1px solid #e6e6e6;
        border-radius: 261px;
        margin: 0px 5px;

        @media screen and (max-width: 538px) {
          // margin-bottom: 10px;
        }
        @media screen and (max-width: 480px) {
          // margin-bottom: unset;
        }
        img {
          cursor: pointer;
        }
      }
      .upload-btn-wrapper input[type="file"] {
        font-size: 0px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        width: 60px;
        height: 60px;
      }

      .input-group {
        min-width: 400px;
        background: #ffffff;
        box-shadow: 0px 3px 6px #2e2e2e12;
        border: 1px solid #e6e6e6;
        border-radius: 261px;
        overflow: hidden;
        padding: 12px 10px 8px 10px;
        margin-right: 14px;
        @media screen and (max-width: 480px) {
          min-width: 100%;
          margin-top: 20px;
        }
        input {
          border: none;
          font-size: 19px;
          padding: 0px 30px;
          &:focus,
          &:hover {
            box-shadow: none;
            border: none;
          }
        }
        .input-group-text {
          border: none;
          background-color: unset;
          img {
            width: 27px;
            height: auto;
          }
        }
      }

      .btn-primary {
        cursor: pointer;
        width: 40px;
        height: 40px;
        padding: 8px;
        background: #ffffff 0% 0% no-repeat padding-box;
        color: rgba(0, 0, 0, 0.795);
        font-weight: bold;
        font-size: 17px;
        box-shadow: 0px 3px 6px #2e2e2e12;
        border: 1px solid #e6e6e6;
        border-radius: 261px;
        margin: 0px 5px;
        &:focus,
        &:focus-visible {
          box-shadow: 0px 3px 6px #2e2e2e12;
          outline: unset;
        }
      }
      .notification-wrap {
        position: relative;
        span {
          position: absolute;
          min-width: 22px;
          text-align: center;
          top: 0;
          right: 0;
          font-size: 12px;
          border-radius: 50%;
          background-color: red;
          margin: auto;
          padding: 3px;
          color: white;
          @media screen and (max-width: 480px) {
            top: 17px;
          }
        }
      }
      .profile-status-btn{
        background: #A38569 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #9E9E9E29;
        opacity: 1;
        border: none;
        border-radius: 5px;
        font: normal normal medium 16px/25px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        padding: 8px;
        display: flex;
        outline: none;
        p{
          width: 22px;
          height: 22px;
          background: #F2F6F7 0% 0% no-repeat padding-box;
          border-radius: 50%;
          margin-left: 10px;
          color: black;
        }
      }
      .notification {
        cursor: pointer;
        width: 40px;
        height: 40px;
        padding: 8px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #2e2e2e12;
        border: 1px solid #e6e6e6;
        border-radius: 261px;
        margin: 0px 5px;
        @media screen and (max-width: 480px) {
          display: block;
          // margin-top: 15px;
        }
      }
    }
  }
}
.srv-validation-message {
  color: red;
}

.swal2-container.swal2-center > .swal2-popup {
  height: 280px;
  width: 28em;
  .swal2-actions {
    justify-content: flex-end;
    padding-right: 20px;
    .swal2-styled.swal2-default-outline {
      &:focus {
        box-shadow: unset;
      }
    }
  }
  .swal2-close {
    color: #000;
    &:hover {
      color: #000;
    }
  }
  .swal2-styled.swal2-cancel {
    color: #000;
  }
  .swal2-styled.swal2-confirm {
    padding: 10px 33px;
  }
  .swal2-html-container {
    color: #000;
    font-size: 20px;
    font-family: $font-bold;
    margin-top: 50px;
  }
}
