@import "../../../styles/TextStyles.scss";

.example-custom-input {
  cursor: pointer;
  padding: 5px 15px;
  border: 0;
  border-radius: 0px !important;
  background-color: unset !important;
  font: inherit;
  color: #101928;
  width: 133% !important;
  height: 35px;
  margin-left: -41px !important;
  font-weight: bold;
  &:hover {
    box-shadow: unset !important;
    border-radius: 0px !important;
  }
}
.add-appointment {
  .app-form-title {
    @media screen and (max-width: 992px) {
      padding-left: unset;
      h2 {
        padding-left: unset;
        @media screen and (max-width: 480px) {
          text-align: center !important;
        }
      }
      img {
        @media screen and (max-width: 480px) {
          display: none;
        }
      }
    }
  }
  h2 {
    text-align: center;
    font-size: 22px;
    font-family: $font-bold;
    background-color: #f6f6f6;
    padding: 20px;
  }
  .main-container {
    height: calc(100vh - 60px);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .left-block {
      width: calc(100% - 440px);
      overflow-y: auto;
      .left-block-wrapper {
        max-width: calc(100% - 15vw);
        margin: auto;
        position: relative;
        @media screen and (max-width: 992px) {
          max-width: 100%;
          padding: 0 15px;
        }
        .total-save {
          justify-content: space-evenly;
          align-items: center;
          .save-button-side-panel {
            margin: unset;
            width: 30%;
          }

          .total {
            font-family: "Nunito Sans-extrabold";
            font-size: 17px;
          }
          @media screen and (min-width: 992px) {
            display: none;
          }
        }
      }

      @media screen and (min-width: 768px) {
        padding-right: unset;
      }
      @media screen and (min-width: 900px) {
        padding-right: 15px;
      }
      .divider-line {
        position: absolute;
        width: 1px;
        height: 200px;
        left: -20px;
        top: 15px;
        border-left: 1px solid #d9d9d9;
        z-index: -1;
        @media screen and (max-width: 640px) {
          margin-left: 17px;
        }
      }
      .dropdown-menu {
        overflow-y: hidden !important;
        inset: 0px auto auto 0px !important;
        border: unset;
        box-shadow: 0px 1px 10px #a7a7a740;
        @media screen and (min-width: 1073px) {
          inset: 0px auto auto -90px !important;
        }
      }
      .radio-area {
        padding-right: unset;
        right: 2px;
        .radio-group {
          display: flex !important;
          justify-content: flex-end;
          .custom-dropdown {
            .dropdown-toggle {
              box-shadow: unset !important;
            }
          }
        }
      }
      .radio-group-row {
        .react-datepicker-popper[data-placement^="bottom"] {
          padding-top: 10px;

          // margin-left: -120px !important;
          z-index: 2;
          position: relative;
          @media screen and (max-width: 485px) {
            padding-top: 10px;

            margin-left: 0px !important;
          }
        }
        .react-datepicker__triangle::after {
          border-top: none;
          border-bottom-color: #ffffff;
        }
        .react-datepicker__triangle {
          position: absolute;
          left: 200px !important;
          transform: translate(0px, 0px);

          @media screen and (max-width: 990px) {
            left: 25px !important;
          }
          @media screen and (min-width: 989px) {
            left: 25px !important;
          }
        }

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .react-datepicker-wrapper {
          min-width: 200px;
          width: fit-content;
          margin-right: 10px;
          margin-bottom: 8px;
          button {
            background-color: transparent;
            outline: unset;
          }
        }
        .react-datepicker {
          font-size: 0.8rem;
          background-color: #fff;
          color: #000;
          border: unset !important;
          border-radius: unset !important;
          display: inline-block;
          position: relative;
          margin-left: -150px !important;
          width: 400px !important;
          height: 100% !important;
          box-shadow: 3px 4px 21px 2px rgba(46, 29, 29, 0.1);
          border-radius: 6px !important;
          @media screen and (min-width: 989px) {
            margin-left: -50px !important;
            max-width: 400px !important;
          }
          @media screen and (max-width: 990px) {
            margin-left: -70px !important;
            max-width: 400px !important;
          }
          @media screen and (max-width: 484px) {
            margin-left: 0px !important;
            max-width: 330px !important;
          }
          .react-datepicker__navigation {
            margin-top: 27px;
          }

          .react-datepicker__header {
            text-align: center;
            background-color: unset !important;
            border-bottom: unset !important;
            border-top-left-radius: unset !important;
            padding: 8px 0;
            position: relative;
          }
          .react-datepicker__day-names {
            margin-bottom: -8px;

            display: flex !important;
            justify-content: space-around !important;
            font-size: 18px !important;
            margin-top: 20px !important;
          }
          .react-datepicker__week {
            white-space: nowrap;
            display: flex !important;
            justify-content: space-around !important;
            font-size: 15px;
            margin-top: 12px !important;
            .react-datepicker__day--selected {
              border-radius: 1.3rem !important;
              background-color: #060707;
              color: #fff;
            }
          }

          .react-datepicker__month-container {
            float: left;
            width: 100% !important;
            // height: 400px;
            .react-datepicker__current-month {
              margin-top: 20px !important;
              font-size: 20px !important;
            }
          }
        }
        .example-custom-input {
          margin-left: unset !important;
          text-align: left !important;
          padding-left: unset !important;
        }
        .radio-group {
          margin-bottom: 8px;
          .dropdown-toggle {
            text-align: end !important;
            padding-right: 0px !important;
          }
          .dropdown-toggle::after {
            height: unset !important;
            width: unset !important;
            border-left: unset !important;
            border-bottom: unset !important;
            border-right: unset !important;
            border-top: unset !important;
          }
          .m-auto {
            margin-right: -10px !important;
          }
        }
        .partners-dropdown {
          display: flex;
          justify-content: flex-end;
          @media screen and (max-width: 1289px) {
            flex: auto;
          }
          img {
            width: 26px;
          }
          .custom-dropdown {
            width: 163px;
            .dropdown {
              button {
                padding: 0 4px;
                border: unset;
                background-color: unset;
              }
            }
          }
        }
      }
      .secondary-button-container {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .primary-button-container {
        display: inline-block;
        margin: auto;
      }
      .service-block {
        display: flex;
        position: relative;
        .mark-start {
          position: absolute;
          text-align: center;
          color: white;
          top: 65px !important;
          left: -12% !important;
          width: 26px !important;
          height: 26px !important;
          border-radius: 100%;
          background-color: #0b586d;
          z-index: 1;
          .vl {
            border-left: 2px dotted #cbcbcb !important;
            position: absolute;
            left: 50%;
            margin-left: -1px;
            top: 26px !important;
          }
          @media screen and (min-width: 1025px) {
            left: -7% !important;
          }
          @media screen and (max-width: 480px) {
            visibility: hidden;
          }
          @media (min-width: 1920px) and (max-width: 1920px) {
            left: -6% !important;
          }
        }
        .mark-end {
          content: "";
          color: white;
          text-align: center;
          position: absolute;
          width: 26px !important;
          height: 26px !important;
          top: 90% !important;
          left: -12% !important;
          border-radius: 100%;
          border: 1px solid #d9d9d9;
          background-color: rgba(255, 255, 255, 0);
          @media screen and (min-width: 1025px) {
            left: -7% !important;
          }
          @media screen and (max-width: 480px) {
            visibility: hidden;
          }
          @media (min-width: 1920px) and (max-width: 1920px) {
            left: -6% !important;
          }
        }
        .mt-4 {
          flex: 1;
          .col-md-8,
          .col-md-4 {
            padding: 10px;

            @media screen and (max-width: 992px) {
              padding: 10px !important;
            }
          }
          @media screen and (min-width: 768px) {
            .col-md-4 {
              padding-right: unset;
            }
          }
          @media screen and (min-width: 900px) {
            .col-md-4 {
              padding-right: 15px;
            }
          }
          input {
            max-height: 100% !important ;
          }
          .dropdown button {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .custom-dropdown {
            & > div {
              height: 57px !important;
            }
            &::active {
              height: 57px;
            }
          }
        }
        .warning {
          color: #dc3545;
          font-family: $font-bold;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .btn {
            background-color: #0b586d;
            border-color: #0b586d;
            &:hover,
            &:disabled,
            &:focus,
            &:active {
              background-color: #0b586d !important;
              border-color: #0b586d !important;
            }
            &:hover,
            &:focus {
              box-shadow: 0 4px 12px -3px #0b586d !important;
            }
          }
        }

        .remove-service-button {
          position: absolute;
          margin-top: 1.5rem;
          padding: 0;
          background: unset;
          border: unset;
          margin-bottom: auto;
          z-index: 21;
          right: -3px;
          top: 5px;
          outline: unset;
          box-shadow: unset;
          z-index: auto;
          img {
            max-width: 15px;
          }
          @media screen and (min-width: 768px) {
            right: -15px;
          }
          @media screen and (min-width: 900px) {
            right: 0;
          }
        }
        .add-service-button {
          position: absolute;
          right: 0px !important;
          bottom: -11% !important;
          padding-right: 0px !important;
          cursor: pointer !important;
          color: #0b586d;
          z-index: 21;
          font-weight: bold;
          outline: unset;
          box-shadow: unset;
          z-index: auto;
          @media screen and (max-width: 670px) {
            bottom: -6% !important;
          }
          img {
            max-width: 15px;
          }

          @media screen and (min-width: 768px) {
            right: -15px;
          }
          @media screen and (max-width: 767px) {
            bottom: -6%;
          }
          @media screen and (min-width: 900px) {
            right: 0;
          }
        }

        .add-services {
          width: 100%;
          background-color: #ffffff;
          border-radius: 5px;
          padding: 15px 25px;
          // margin-inline: unset !important;
        }
        // .transparent {
        //   opacity: 0;
        // }
        .service-block-tranisition {
          animation: slideIn 0.5s ease-out;
          opacity: 1;
        }
        .css-q4ifmu-control {
          box-shadow: 4px 2px 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
      .mt-4 {
        .notes {
          margin-top: 10px;
          @media screen and (min-width: 900px) {
            padding-right: 15px;
          }
        }
      }
      .customer-details-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 8px 24px;
        cursor: pointer;
        // border-bottom: 1px solid #eef0f2;
        img {
          border-radius: 50%;
          width: 60px;
          height: 60px;
          margin-right: 10px;
        }
        p {
          text-transform: capitalize;
        }
        .three-dot-icon {
          width: 20px;
          height: 20px;
        }
        @media screen and (min-width: 992px) {
          display: none;
        }
      }
      .addClient {
        color: #0b586d;
        font-weight: bold;
        cursor: pointer;
        font-size: 17px;
        padding-block: 10px;
        @media screen and (min-width: 992px) {
          display: none;
        }
      }
      .justify-content-end {
        .col-md-auto {
          @media screen and (min-width: 768px) {
            padding-right: unset;
          }
          @media screen and (max-width: 767px) {
            text-align: end;
          }
          @media screen and (min-width: 900px) {
            padding-right: 15px;
          }
        }
      }
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }

    .search-panel {
      padding: 0px;
      height: 100%;
      width: 440px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e6e6e6;
      .right-container {
        height: 100%;
        .three-dot-icon {
          position: absolute;
          right: 10px;
          top: 85px;
          margin-right: 10px;
          cursor: pointer;
        }
        .bottom-sec {
          display: flex;
          height: calc(100% - 80px);
          flex-direction: column;
          justify-content: space-between;
          .empty-1 {
            padding: 25% 0 !important ;
            font-family: $font-semibold;
            font-size: 16px;
            color: #959595;
            text-align: center;
          }
          .search-list {
            flex: 1;
            overflow-y: auto;
            margin-bottom: 24px;
            .client-info-block {
              padding: 10px 0;
              height: calc(100% - 77px);
              display: flex;
              flex-direction: column;
              div {
                margin: 5px 10px;
                border-bottom: 1px solid #eef0f2;
              }
              .new-client-btn {
                padding-bottom: 10px;
                text-align: end;
                button {
                  background-color: #007bffa3 !important;
                  border-color: #007bffa3 !important;
                }
              }
              .summary-block {
                display: flex;
                justify-content: space-around;
                div {
                  text-align: center;
                }
              }
              .info-tabs {
                display: flex;
                justify-content: space-around;
                span {
                  cursor: pointer;
                }
              }
              .selected-tab {
                border-bottom: 2px solid #000;
              }
              .tab-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                .no-history-image {
                  max-width: 80px;
                }
                .filled-data {
                  text-align: left;
                  label {
                    font-size: 18px;
                    font-family: $font-semibold;
                    color: #959595;
                    margin: 0;
                    margin-top: 26px;
                  }

                  .response {
                    font-size: 15px;
                    font-family: $font-semibold;
                    color: #000;
                  }
                }
              }
            }
          }
          .customer-details-wrapper {
            display: flex;
            align-items: center;
            padding: 8px 24px;
            cursor: pointer;
            border-bottom: 1px solid #eef0f2;
            img {
              border-radius: 50%;
              width: 60px;
              height: 60px;
              margin-right: 10px;
            }
            p {
              text-transform: capitalize;
            }
          }
          .empty-2 {
            display: block;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            .save-button-side-panel {
              max-width: 90%;
              margin-left: auto;
              margin-right: auto;
            }
            .total {
              font-family: $font-extrabold;
              font-size: 17px;
              color: #000;
              text-align: center;
              margin-top: 15px;
              margin-bottom: 15px !important;
            }
            .primary-button-container {
              width: 100%;
            }
          }
          .custom-input-search {
            padding-bottom: 10px !important;
          }
        }
      }
      @media screen and (max-width: 992px) {
        display: none;
      }
    }
    .scroll-hidden {
      overflow: hidden;
    }
    @media screen and (min-width: 992px) {
      padding-right: unset !important;
    }
  }
  .cancel-icon {
    cursor: pointer;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 99999;
  }
}
.remove-selected-item {
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
}
.service-menu-custom {
  max-height: 350px;
  overflow-y: auto;
  .category-block {
    .category-name {
      font-size: 20px;
      font-family: $font-bold;
      padding: 21px 24px 8px 24px;
      // border-bottom: 1px solid #eef0f2;
    }
    .custom-service-block {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      min-height: 90px;
      padding: 21px 24px;
      border-bottom: 1px solid #eef0f2;
      .service-name {
        color: #000000;
        font-size: 17px;
        font-family: $font-bold;
        .duration {
          color: #959595;
          font-size: 17px;
          font-family: $font-regular;
        }
      }
      .service-name,
      .service-amount {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .original-amount {
          color: #959595;
          font-size: 17px;
          font-family: $font-regular;
          text-decoration: line-through;
        }
      }
      .service-amount {
        font-size: 17px;
        font-family: $font-bold;
        span {
          text-align: right;
        }
      }
      &:hover {
        background-color: #fbfbfb !important;
      }
    }
  }
  .no-options {
    color: hsl(0, 0%, 60%);
    padding: 8px 12px;
    text-align: center;
    box-sizing: border-box;
  }
}

.no-padding {
  button {
    padding: 0.375rem 0.75rem !important;
    border: unset !important;
    background-color: unset !important;
  }
}

.select-panel {
  padding: 0px;
  height: 100%;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  .right-container {
    height: 100%;
    .three-dot-icon {
      position: absolute;
      right: 10px;
      top: 5px;
      cursor: pointer;
    }
    .bottom-sec {
      display: flex;
      height: calc(100% - 80px);
      flex-direction: column;
      justify-content: space-between;
      .empty-1 {
        padding: 25% 0 !important ;
        font-family: $font-semibold;
        font-size: 16px;
        color: #959595;
        text-align: center;
      }
      .search-list {
        flex: 1;
        overflow-y: auto;
        margin-bottom: 24px;
      }
      .customer-details-wrapper {
        display: flex;
        align-items: center;
        padding: 8px 24px;
        cursor: pointer;
        border-bottom: 1px solid #eef0f2;
        img {
          border-radius: 50%;
          width: 60px;
          height: 60px;
          margin-right: 10px;
        }
        p {
          text-transform: capitalize;
        }
      }
      .custom-input-search {
        padding-bottom: 10px !important;
      }
    }
  }
  .add-client {
    position: absolute;
    bottom: 11px;
    right: 31px;
  }
  @media screen and (min-width: 992px) {
    // display: none;
  }
}
@keyframes slideIn {
  0% {
    opacity: 0;
    height: 0;
    transform: translateY(100%);
  }
  // 25% {
  //   transform: translateY(-75%);
  //   opacity: 0.25;
  // }
  50% {
    // transform: translateY(50%);
    height: 50%;
    opacity: 0.5;
  }
  // 75% {
  //   transform: translateY(-25%);
  //   opacity: 0.75;
  // }
  100% {
    transform: translateY(0);
    height: 100%;
    opacity: 1;
  }
}
