@import "../../../styles/TextStyles.scss";

.cumulative-customers-rpt {

  .no-data-text {
    text-align: center;
  }

  .primary-button-container {
    margin-top: 40px !important;
  }

  @media (max-width: 992px) {
    .filter-item {
      margin-top: 10px !important;
    }
  }

  @media (max-width: 768px) {
    .primary-button-container {
      margin-top: 10px !important;
    }
  }
}