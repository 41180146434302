@import "../../styles/TextStyles.scss";
.date-input {
  label {
    font-family: $font-bold;
    font-size: 15px;
    color: #1d1d1d;
    margin-bottom: 0px;
  }
.react-datepicker-wrapper{
  display: block;
  width: 100%;
  input {
    width: 100%;
    margin-top: 13px;
    height: 57px;
    padding: 20px 10px;
    color: #000;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #8282821a;
    font-size: 15px;
    border: 1px solid #c7c7c7;

    &:focus,
    &:hover,
    &:active,&:focus-visible {
      border-color: #0b586d;
    }
  }
  button{
    top: 5px !important;
    right: 5px !important;
  }
}
}
