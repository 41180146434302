@import "../../../styles/TextStyles.scss";
.secondary-button-container {
  border-radius: 5px;
  background-color: #fff;
  padding: 14px 30px 14px;
  transition: all 0.2s ease-in;
  border: 1px solid #e6e6e6;
  max-height: 52px;
  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 12px -3px #e6e6e6;
  }

  .button-text {
    color: #6d6d6d;
    font-size: 15px;
    font-family: $font-semibold;
    text-align: center;
  }
}
.secondary-button-container.loader {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
