.staff-member {
  margin-top: 30px;
  .top-cta-row {
    margin-top: -10px;
    justify-content: space-between;

    .custom-input-search {
      padding: 0px;
      .input-group {
        border-radius: 154px;
      }
    }
    .cta-staff {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-right: 15px;
      @media screen and (max-width: 480px) {
        margin-top: 15px;
      }
      .btn {
        min-width: 128px;
      }
    }
  }

  .listing-card-row {
    margin-top: 35px;
    .custom-card {
      margin-bottom: 12px;
      border-radius: 7px;
      box-shadow: 0px 1px 5px #9f9f9f29;
    }
    .spinner-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      span {
        margin-left: 10px;
      }
    }
    .no-members-container {
      flex: 1;
      text-align: center;
    }
  }
}
