@import "../../../styles/TextStyles.scss";
.primary-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  border-radius: 5px;
  background-color: #0b586d;
  // display: block;
  padding: 14px 30px 14px;
  transition: all 0.2s ease-in;
  max-height: 52px !important;
  &:hover,
  &:disabled,
  &:focus,
  &:active {
    background-color: #0b586d !important;
    border-color: #0b586d !important;
  }
  &:hover,
  &:focus {
    box-shadow: 0 4px 12px -3px #0b586d !important;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    box-shadow: unset !important;
  }
  .button-text {
    color: #ffffff;
    font-size: 15px;
    font-family: $font-semibold;
    text-align: center;
  }
  .primary-tm-image{
    padding-left: 10px;
  }
}
