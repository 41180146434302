@import "../../../styles/TextStyles.scss";
.employee-table {
  margin-top: 25px;
  overflow-x: auto;
  // padding: unset;
  .table {
    tr {
      cursor: pointer;
      th {
        vertical-align: middle;
        padding: 10px;
        font-family: $font-bold;
        white-space: nowrap;
      }
      td {
        color: #5d5d5d;
        padding: 10px;
        font-family: $font-semibold;
        .delete {
          padding: 5px;
        }
        .profile-pic {
          width: 60px;
          height: 60px;
          border-radius: 100%;
          object-fit: cover;
          margin-right: 20px;
        }
      }
    }
  }

  .pagination {
    justify-content: space-between;
    align-items: center;
    .result-text {
      color: #6d6d6d;
      font-size: 15px;
      @media screen and (max-width: 480px) {
        text-align: center;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
    }
    .count {
      display: flex;
      width: 100%;
      cursor: pointer;
      justify-content: flex-end;
      @media screen and (max-width: 480px) {
        justify-content: center;
      }
      @media screen and (max-width: 767px) {
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .previous,
      .next {
        min-width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #d9d9d9;
        border-radius: 100%;
      }
      .previous {
        margin-right: 20px;
        @media (max-width: 400px) {
          margin-right: 5px;
        }
      }
      .next {
        margin-left: 20px;
        @media (max-width: 400px) {
          margin-left: 5px;
        }
      }
      .pages {
        height: 40px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #d9d9d9;
        border-radius: 30px;
        font-family: $font-semibold;
        font-size: 14px;
        color: #989898;
        padding: 6px 15px;

        justify-content: space-evenly;
        span {
          vertical-align: middle;
          padding: 11px 10px;
          &.active {
            color: #ffffff;
            background: #0b586d;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .danger-button-container {
    max-height: 45px;
    .button-text {
      font-size: 13px;
    }
  }
  .actions-cont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.spinner-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 480px) {
    margin-top: 15px;
  }
  span {
    margin-left: 10px;
  }
}
.no-data-container {
  text-align: center;
}
