.card {
  padding: 15px;
  margin-top: 25px;
  .table-wrapper {
    th {
      &:last-child {
        text-align: start;
      }
    }
    .otherWrap {
      .edit {
        margin-right: 5px;
        cursor: pointer;
      }
      .delete{
        cursor: pointer;
      }
    }
  }
}
