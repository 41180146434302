@import "../../../styles/TextStyles.scss";

.transactionsPerWeek {

  .no-data-text {
    text-align: center;
  }

  .primary-button-container {
    margin-top: 40px !important;
  }


  .booking-table {
    padding: unset;
    margin-top: 42px;

    .table {
      tr {
        th {
          padding: 10px;
          vertical-align: middle;
          white-space: nowrap;
        }

        td {
          padding: 14px;
          color: #5d5d5d;

          .status-formatter {
            padding: 4px 8px;
            border-radius: 8px;
            text-align: center;
            max-width: 100px;
            font-size: 16px;
            font-family: $font-extrabold;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .primary-button-container {
      margin-top: 10px !important;
    }
  }
}