.payout-table {
  // padding: unset;
  margin-top: unset;
  .table-panel {
    margin-top: 25px;
    .react-bootstrap-table {
      overflow: auto;
    }
  }

  .react-bootstrap-table {
    .table {
      table-layout: unset;
    }
    // tr:nth-of-type(odd) td {
    //   background-color: #ecf0f5;
    // }
    td {
      &:last-child {
        text-align: center;
      }
      ul {
        list-style: none;
      }
    }
  }
}
.table-wrapper {
  .search-wrap {
    margin-top: 25px;
    margin-bottom: 20px;
    justify-content: flex-end;
    .custom-input-search {
      padding: 5px 10px;
    }
    .col-md-3 {
      display: flex;
      align-items: center;
    }
    span {
      color: #5d5d5d;
    }
    .custom-dropdown {
      margin-inline: 10px;
      .css-g1d714-ValueContainer {
        justify-content: center;
      }
    }
  }
  .payouts-heading {
    h5 {
      color: #444;
    }
  }
}
.no-data {
  margin-top: 20px;
  text-align: center;
  color: #696969;
}
.nav-link {
  color: #4731bfe0;
}
.pagination {
  justify-content: space-between;
  align-items: center;
  .result-text {
    color: #6d6d6d;
    font-size: 15px;
    @media screen and (max-width: 480px) {
      text-align: center;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
  .count {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: flex-end;
    @media screen and (max-width: 480px) {
      justify-content: center;
    }
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .previous,
    .next {
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d9d9d9;
      border-radius: 100%;
    }
    .previous {
      margin-right: 20px;
      @media (max-width: 400px) {
        margin-right: 5px;
      }
    }
    .next {
      margin-left: 20px;
      @media (max-width: 400px) {
        margin-left: 5px;
      }
    }
    .pages {
      height: 40px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d9d9d9;
      border-radius: 30px;
      font-size: 14px;
      color: #989898;
      padding: 6px 15px;

      justify-content: space-evenly;
      span {
        vertical-align: middle;
        padding: 11px 10px;
        &.active {
          color: #ffffff;
          background: #0b586d;
          border-radius: 5px;
        }
      }
    }
  }
}
.status-formatter {
  border-radius: 8px;
  padding: 4px 8px;
}
