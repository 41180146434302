@import "../../../styles/TextStyles.scss";

.rounded-button {
  font-family: $font-semibold;
  padding: 7px 25px;
  background: rgba(#0b586d, 0.1);
  color: #000;
  font-size: 16px;
  border-radius: 491px;
  margin-right: 20px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  &.active {
    color: #fff;
    background: #0b586d;
  }

  &:hover {
    cursor: pointer;
    background-color: #0b586d;
    color: #fff;
    box-shadow: 0 4px 12px -3px #0b586d;
  }
}
