@import "../../../styles/TextStyles.scss";
@import "../../../styles/Colors.scss";

.signUpPage {

  .left-sec-content {
    padding: 0;
    object-fit: cover;
    width: 100%;
    height: 100vh;

    @media (max-width:768px) {
      display: none;
    }
  }

  .left-img-cont {
    background-image: url('../../../assets//images/sign-up-image.jpg');
    background-size: cover;
    background-position: unset;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  .right-sec-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: fit-content;
    max-height: 100vh;
    overflow-y: scroll;
  }

  .right-sec-content {
    padding: 20px 20px;
    max-width: 400px;
    margin: auto;

    @media (max-width:768px) {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 5px #9F9F9F29;
      border-radius: 7px;
      margin: unset;
      padding: 20px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;

      .logo {}

      .lang-cont {}
    }

    .heading-cont {
      .heading {
        font: normal normal bold 25px/30px Nunito Sans;
        letter-spacing: 0px;
        color: #000000;
      }

      .subheading-cont {
        font: normal normal bold 25px/30px Nunito Sans;
        letter-spacing: 0px;
        color: #000000;

        span {
          color: #0B586D;
        }
      }
    }

    .form-cont {
      padding-top: 30px;

      .sign-in-heading {
        font: normal normal normal 20px/20px Nunito Sans;
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.5;
      }

      .custom-input {
        margin-bottom: 10px;
      }

      .input-group {
        margin-top: 5px !important;
      }

      .select-business-types-header {
        font-family: "Nunito Sans-bold";
        font-size: 15px;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .business-type-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: black;

        .business-type-item {
          width: 50%;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f6f6f6;
          border: 1px solid rgb(79, 79, 79);
          color: black;
          border-radius: 10px;
          transition: all 0.2s ease-in;
          cursor: pointer;

          &:hover {
            box-shadow: 0 4px 12px -3px #020202 !important;
          }
        }

        .business-type-item-active {
          border: 1px solid black;
          background-color: #0b586d;
          color: white;
        }
      }

      .border {
        border: 1px solid grey;
        height: 1px;
        width: 100%;
        margin-top: 30px;
      }

      .signin-link {
        margin-top: 26px;
        color: #959595;
        font-family: $font-semibold;
        font-size: 14px;
        text-align: center;

        a {
          color: #629dd9;
          font-size: 14px;
          font-family: $font-semibold;
        }
      }
    }

    .sigin-up-cont {
      .go-back-cont {
        .left-arr {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }

    }
  }

  .step-2 {
    max-width: 500px;
  }

}