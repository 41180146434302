@import "../../../../styles/TextStyles.scss";
.view-appointment {
  position: relative;
  h2 {
    text-align: center;
    font-size: 29px;
    font-family: $font-bold;
    padding: 20px;
  }
  h5 {
    position: absolute;
    left: calc(50% - 40px);
  }
  .journey-status {
    span {
      font-weight: 600;
    }
  }
  .main-container {
    height: calc(100vh - 75px);
    overflow-y: hidden;
    .left {
      height: 100%;
      overflow-y: hidden;
      .service-menu-options {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      @media screen and (max-width: 991px) {
        height: calc(100% - 39px);
      }
      .service-menu-options {
        @media screen and (min-width: 991px) {
          display: none;
        }
      }
    }
    .left::-webkit-scrollbar{
      // display: none;
      width: 5px;
    }
    /* Track */
    .left::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
    /* Handle */
    .left::-webkit-scrollbar-thumb {
      background: grey; 
      border-radius: 10px;
    }
    /* Handle on hover */
    .left::-webkit-scrollbar-thumb:hover {
      background: grey; 
    }                             

    .history-block {
      margin-top: 0px !important;

      .name {
        font-size: 25px;
        font-family: $font-bold;
      }
      .calculation {
        margin-top: 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid #8282821a;
        .time {
          font-size: 16px;
          font-family: $font-semibold;
          color: #959595;
        }
        .product-name {
          font-size: 17px;
          font-family: $font-bold;
          .duration {
            font-size: 16px;
            color: #959595;
            font-family: $font-semibold;
          }
        }
        .product-amount {
          font-size: 15px;
          font-family: $font-bold;
        }
      }
      .charges-details-cont{
        padding: 0px 15px;
      }
      .total {
        margin-top: 10px !important;
        border-bottom: 1px solid rgb(232, 232, 232);
        .duration {
          font-size: 16px;
          color: #959595;
          font-family: $font-semibold;
          padding: 0;
          span{
            font-family: $font-extrabold ;
            color: black !important;
          }
        }
        .amount {
          font-size: 15px;
          font-family: $font-bold;
          text-align: right;
          padding: 0;
        }
      }
      .appointment-timeline {
        margin-top: 30px;

        .heading {
          font-family: $font-extrabold;
          font-size: 17px;
          margin-bottom: 20px;
        }
        ul {
          position: relative;
          list-style: none;
          li {
            position: relative;
            padding-left: 30px;
            padding-bottom: 15px;
            &:last-child {
              &::after {
                display: none;
              }
            }
            &::before {
              content: " ";
              width: 10px;
              height: 10px;
              position: absolute;
              top: 7px;
              left: 10px;
              border-radius: 100%;
              background: #0b586d;
            }
            &::after {
              content: " ";
              position: absolute;
              left: 14px;
              border-left: 1px solid #8282821a;
              top: 5px;
              bottom: 0px;
            }
            &.two {
              &::before {
                background: #5a95a5;
              }
            }
            &.three {
              &::before {
                background: #a4c1c9;
              }
            }
            &.four {
              &::before {
                background: #d7dbdc;
              }
            }
          }
        }
      }
      .client-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        img {
          width: 63px;
          height: 63px;
          object-fit: cover;
          border-radius: 100%;
          margin-right: 27px;
        }
        .email-cont {
          cursor: pointer;
          label {
            font-size: 18px;
            font-family: $font-semibold;
            color: #959595;
            margin: 0px;
            cursor: pointer;
          }
          .value {
            font-size: 15px;
            color: #000;
          }
        }
      }
      .client-status {
        @media screen and (min-width: 991px) {
          display: none !important;
        }
      }
      .status-options {
        border-bottom: 1px solid #8282821a;
        padding-bottom: 15px;
        .invoice-link {
          display: flex;
          width: fit-content;
          margin: auto;
        }
        .no-invoice-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          .no-invoice {
            text-align: center;
            font-size: 16px;
            font-family: $font-semibold;
            color: #959595;
          }
        }
      }
    }
    .invoice-panel {
      padding: 0px;
      height: 100%;
      background: #fff;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      border-left: 1px solid #8282821a;
      // visibility: hidden;
      .client-details {
        padding: 30px 45px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        img {
          width: 63px;
          height: 63px;
          object-fit: cover;
          border-radius: 100%;
          margin-right: 27px;
        }
        .email-cont {
          cursor: pointer;
          label {
            font-size: 18px;
            font-family: $font-semibold;
            color: #959595;
            margin: 0px;
            cursor: pointer;
          }
          .value {
            font-size: 15px;
            color: #000;
          }
        }
      }
      .status {
        background: #f6f6f6;
        padding: 15px 20px;
        .dropdown {
          width: 211px;
          margin: 0 auto;
          button {
            width: 211px;
            margin: 0 auto;
            text-align: center;
          }
        }
      }
      .invoice {
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        .container-fluid {
          height: 100%;
          .row {
            justify-content: center;
            align-items: flex-center;
            height: 100%;
            flex-direction: column;
            .col {
              flex-basis: 0;
              -ms-flex-positive: 1;
              flex-grow: 1;
              min-width: 0;
              max-width: 100%;
              display: flex;
            }
            .no-invoice-wrap {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              .bill-image {
                max-width: 100px;
              }
              .no-invoice {
                text-align: center;
                font-size: 16px;
                font-family: $font-semibold;
                color: #959595;
              }
            }

            .invoice-link {
              display: flex;
              width: fit-content;
              margin: auto;
            }

            .invoice-total {
              text-align: center !important;
            }
            .service-menu {
              display: flex;
              margin: 10px auto;
              .dropdown-item {
                &:active {
                  background-color: #f8f9fa;
                }
              }
              .custom-option {
                color: #cd8599;
              }
              button {
                min-width: 150px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
        }
      }
      @media screen and (max-width: 991px) {
        display: none !important;
      }
    }
  }
  .summary-block {
    h5 {
      position: unset !important;
    }
  }
}
