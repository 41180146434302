@import "../../../styles/TextStyles.scss";

.calender-grid {
  padding-left: 0px;
  height: 100%;
  .add-app {
    overflow-y: unset;
  }
  .view-app {
    overflow-y: unset;
  }
  

  .cal-block-slot-header{
    width: 100%;
    height: 60px;
    background-color: #0B586D;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: .5s;
    .cal-block-slot-header-cont{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 0px 30px;

      p{
        color: white;
      }
      img{
        cursor: pointer;
      }
    }
  }


  // Tootlbar Styles Starts
  .calendar-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    flex-direction: row;
    @media (max-width: 437px) {
      flex-direction: column;
      align-items: flex-end;
    }
    .staff-list{
      width: 160px;
      .css-6iiga6-container {
        height: 40px;
      }
      .custom-dropdown-select__single-value{
        max-width: calc(100% - 50px);
      }
      @media (max-width: 992px) {
        width: 120px;
      }
      @media (max-width: 992px) {
        display: none;
      }
    }
    .calendar-views-list-cont{
      width: 85px;
      margin-left: 15px;
      .css-6iiga6-container {
        height: 40px;
      }
    }
    .calendar-toolbar-col {
      .calendar-controller-col {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .calendar-controller {
          min-height: 40px;
          display: flex;
          justify-content: flex-start;
          margin-right: 15px;
          margin-bottom: unset;
          input {
            height: 40px;
            text-align: center;
            border-radius: 3px;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #bbbbbb29;
            border: 1px solid #e6e6e6;
            margin-inline: 15px;
            @media (min-width: 481px) and (max-width: 634px) {
              margin-bottom: 10px;
              width: 100%;
            }
          }
          .arrow-button {
            height: 40px;
            background-color: #fff;
            transition: all 0.2s ease-in;
            border: 1px solid #e6e6e6;
            width: 41px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            @media (max-width: 576px) {
              display: none;
            }
          }
          .b1 {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            &:hover{
              border: 1px solid #b9b6b6;
            }
          }
          .b2{
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            &:hover{
              border: 1px solid #b9b6b6;
            }
          }
          .today-btn{
            background-color: white;
            height: 40px;
            border: 1px solid #e6e6e6;
            padding: 0px 10px;
            font-size: 15px;
            font-weight: normal;
            color: grey;
            &:hover{
              border: 1px solid #b9b6b6 !important;
            }
            &:focus{
              outline: none !important;
            }
            @media (max-width: 576px) {
              display: none;
            }
          }
          .react-datepicker-wrapper{
            width: 210px;
            padding: 3px 10px 0px 10px;
            border: 1px solid #e6e6e6;
            background-color: white;
            .example-custom-input{
              padding: unset;
              width: 100% !important;
              margin-left: 0 !important;
              white-space: nowrap ;
              font-size: 15px;
              font-weight: normal;
              color: grey;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &:hover{
              border: 1px solid #b9b6b6;
            }
          }
          .react-datepicker-popper{
            z-index: 999;
            animation-name: fade-in;
            animation-fill-mode: both;
            animation-duration: .5s;
          }
        }
        .custom-dropdown {
          height: 61px;
          margin-right: 15px;
          width: 194px;

          @media screen and (max-width:480px) {
            width: 311px;
            margin-right: unset;
          }
          @media screen and (max-width:746px) {
            width: 311px;
            margin-right: unset;
          }
          @media (min-width: 481px) and (max-width: 634px) {
            margin-right: unset;
            flex: auto;
          }
          .dropdown {
            height: 61px;

            button {
              height: 100%;
            }
          }
        }

        .filter-icon2 {
          height: 61px;
          border-radius: 7px;
          background-color: #fff;
          transition: all 0.2s ease-in;
          border: 1px solid #e6e6e6;
          width: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #8f8f8f;
          cursor: pointer;
          &:focus {
            box-shadow: unset !important;
          }
          display: none;
          @media screen and (max-width: 820px) {
            display: block;
          }
        }
      }

      .new-appointment-col {
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width:992px) {
          display: none;
        }
        #add-appointment-btn{
          @media (max-width:992px){
            display: none;
          }
        }
        .secondary-button-container,
        .primary-button-container {
          max-height: unset !important;
          height: 40px;
        }
        .primary-button-container {
          margin-left: 15px;
          @media screen and (max-width:480px) {
            padding: 0px 30px;
            flex: auto;
          }
          @media (min-width: 481px) and (max-width: 531px) {
            padding: 7px 30px !important;
          }
          @media (min-width: 737px) and (max-width: 788px) {
            padding: 8px 30px !important;
          }
        }

        .secondary-button-container {
          width: 40px;
          display: flex;
          justify-content: center;
          border-radius: 7px;
          padding: 0;
          @media (min-width: 481px) and (max-width: 634px) {
            flex: auto;
          }
          @media screen and (max-width:480px) {
            flex: auto;
          }
          img{
            height: 20px;
          }
        }
        .pick-date {
          height: 40px;
          position: relative;
          margin-left: 15px;
          img {
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 15px;
            width: 21px;
            padding: unset;
          }
          .react-datepicker-wrapper {
            height: 100%;
            .react-datepicker__input-container {
              height: 100%;
              .btn {
                text-align: center;
                height: 100%;
                width: 175px;
                border-radius: 7px;
                border: 1px solid #e6e6e6;
                align-items: center;
                background: hsl(0, 0%, 100%);
                border-radius: 7px;
                color: hsl(0, 0%, 20%);
                border: 1px solid #e6e6e6;
                box-shadow: unset !important;
              }
            }
          }
          .react-datepicker-popper {
            z-index: 22;
          }
        }
        .filter-icon {
          padding-top: 0px;
          padding-bottom: 0px;
          height: 40px;
          border-radius: 7px;
          background-color: #fff;
          transition: all 0.2s ease-in;
          border: 1px solid #e6e6e6;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #8f8f8f;
          cursor: pointer;
          &:focus {
            box-shadow: unset !important;
          }
          &:hover{
            border: 1px solid rgb(150, 150, 150);
          }
          img{
            height: 25px;
          }
        }
      }
      
      .small-scr-filters-cont{
        display: none;
       .secondary-button-container{
         width: 40px;
         height: 40px;
         padding: 5px;
         display: flex;
         justify-content: center;
         align-items: center;
         transform: rotate(90deg);
       }

       @media (max-width: 992px) {
         display: block;
       }
     }
    }
  }
  // Tootlbar Styles Ends



  .calendar-row {
    height: calc(100% - 0px);
    .calender-cont {
      height: 100%;
      .fc-media-screen {
        height: 100% !important;
      }
    }
    .calendar-spinner-cont{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 999;
    }
    .fc-view-harness {
      min-width: 600px;
    }







    //============== New calendar styles 


  }

  // Add appointment button on small screens bottom right corner starts
  .small-add-appt-btn{
    display: none;
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 30px;
    .secondary-button-container{
      padding: 10px 10px;
      border-radius: 10px;
      box-shadow: 5px 0px 10px #0B586D0D;
      max-height: unset;
      img{
        width: 35px;
        height: 35px;
      }
    }
    @media (max-width: 992px) {
      display: block;
      animation-name: fade-up;
      animation-fill-mode: both;
      animation-duration: .5s;
    }
  }
  // Add appointment button on small screens bottom right corner ends
  .custom-dropdown-select__indicator-separator{
    top: 3px !important;
  }


  //block slots column opacity
  .block-slot-columns{
    .mbsc-flex-col{
      opacity: 0.8;
    }
  }
}



































// ================ Full calendar styles starts ====================


.fc-toolbar {
  .fc-toolbar-chunk {
    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
    }
  }
}
.fc-toolbar-title {
  color: #6d6d6d;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #bbbbbb29;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  opacity: 1;
  font-size: 17px;
  font-family: $font-bold;
  padding: 15px 20px;
}
.fc-today-button {
  display: none;
}
.fc-button-group {
  .fc-prev-button {
    border: none;
    background-color: #fff !important;
    margin-right: 15px;
    .fc-icon {
      color: #616161;
    }
  }
  .fc-next-button {
    border: none;
    background-color: #fff !important;
    .fc-icon {
      color: #616161;
    }
  }
}
.fc-scrollgrid-sync-inner {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.fc-theme-standard .fc-scrollgrid {
  border: none !important;
  border-top: 1px solid #eee !important;
}
.fc-scrollgrid thead {
  background-color: white;
  .fc-scroller {
    overflow: hidden !important;
  }
}
.fc-scrollgrid-section{
  display: none;
}
.fc-scrollgrid-section:first-child {
  .fc-scroller {
    overflow-y: hidden !important;
  }
}
.fc-scrollgrid-section:last-child {
  display: table-row;
  .fc-scroller {
    overflow-y: auto !important;
  }
}
.fc-scrollgrid tbody {
  .fc-timegrid-slot {
    // border: none !important;
  }
}
.fc-timegrid-slots {
  col{
    background-color: white;
  }
  tr {
    height: 20px;
    .fc-timegrid-slot-label-cushion {
      font-size: 17px;
      font-weight: bold;
    }
  }
  .fc-timegrid-slot-label{
    border: none !important;
  }
  .fc-timegrid-slot-lane{
    border-top: 1px solid grey;
  }
  .fc-timegrid-slot-minor{
    border-top: 1px solid #bbbbbb ;
  }
}
.fc-col-header-cell-cushion {
  background-color: #0b586d;
  font-size: 14px;
  font-family: $font-extrabold;
  color: #fff;
  border-radius: 24px;
  &:hover {
    color: #fff;
  }
}
.fc-v-event {
  border: none !important;
  background: none !important;
  height: fit-content;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start,
.fc-timegrid-event .fc-event-main {
  height: inherit;
  .event-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 15px 36px #d4d4d49c;
    border-radius: 3px;
    border-top: 10px solid;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    .title {
      font-size: 19px;
      overflow-x: hidden;
      font-family: $font-extrabold;
      color: #000;
    }
    .title2 {
      font-size: 19px;
      overflow-x: hidden;
      font-family: $font-extrabold;
      color: #fff;
    }
    .subtitle {
      font-size: 12px;
      font-family: $font-bold;
      overflow-x: hidden;
      color: #9f9f9f;
    }
    .time {
      font-size: 12px;
      font-family: $font-bold;
      overflow-x: hidden;
      color: #9f9f9f;
    }
    .time2 {
      font-size: 12px;
      font-family: $font-bold;
      overflow-x: hidden;
      color: #fff;
    }
  }
  .alert-danger {
    color: #e4b3fc;
  }
  .alert-success {
    color: #98ecc8;
  }
  .alert-warning {
    color: #95b9ff;
  }
  .alert-primary {
    color: #ffa470;
  }
  .alert-default {
    color: #8f8f8f !important;
    background-color: #8f8f8f !important;
  }
}
.fc-resourceTimeGridDay-view {
  .fc-scrollgrid-liquid {
    border-top: unset !important;
    // min-width: 1366px;
    // max-width: 1920px;
    overflow-x: auto;
  }
}
.fc-scroller::-webkit-scrollbar{
  display: none;
}
.fc-timegrid-axis-chunk{
  background-color: white;
  .fc-timegrid-slot-label{
    border: none ;
    border-top: 1px solid white ;
  }
}

.fc-scrollgrid-section-header {
  .fc-timegrid-axis {
    border-right: none;
  }
}
.fc-scrollgrid-section-body {
  .fc-timegrid-axis {
    .fc-timegrid-axis-frame {
      background-image: url("../../../assets/images/clock2.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
    }
  }
}

.staff-icon {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: 1px solid #0b586d;
  border-radius: 50%;
  font-size: 10px;
}
.profile-text {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 1px solid #0b586d;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 800;
  color: #0b586d;
  background-color: #cde4ff;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  font-family: $font-extrabold;
  margin-bottom: 10px;
}
.profile-name {
  color: red;
  background-color: cadetblue;
  height: 45px;
  width: 45px;
  background-color: #bbb;
  border-radius: 50%;
}

// fc-resourceTimeGridDay-view
.fc-resourceTimeGridDay-view {

  .fc-scrollgrid-sync-inner {
    padding: 0;
    border-bottom: unset !important;

    .fc-col-header-cell-cushion {
      background-color: unset;
      padding: 0;
      color: #a4a4a4;
      word-break: break-word;
      &:hover {
        color: #000;
        cursor: pointer;
      }
    }
  }
  .fc-day{
    .fc-non-business{
      // border-right: 1px solid grey;
      // background-size: 15px 15px;
      // background-image: radial-gradient(circle, #000000 1px, rgba(0, 0, 0, 0) 2px);
      // background: repeating-linear-gradient(45deg, #80FFDB 10px, #5390D9 10px);
      // transform: skewY(-11deg);
      // background: repeating-linear-gradient( -45deg, #EEE, #EEEE 14px, #ffff 10px, #000000 15px);
      // background: repeating-linear-gradient(45deg, #fff, #fffe 3px, #a9a8a8 8px);
      // background-image: repeating-linear-gradient(45deg, grey 3px, transparent 5px, transparent 10px);
      background-image: repeating-linear-gradient(45deg, #bbbbbb 0px, transparent 2px, transparent 7px);  
      // background: gray repeating-linear-gradient(45deg, transparent, transparent 1px, #fff 1px, #fff 7px, transparent 7px, transparent 8px, #fff 8px, #fff 14px);
      // background-size: 10px 10px;
    }
  }
  .fc-daygrid-day {
    background-color: transparent !important;
  }
  .fc-day-today {
    background-color: transparent !important;
    .fc-timegrid-bg-harness{
      // border-right: 1px solid grey;
      // background-size: 10px 10px;
      // background-image: radial-gradient(circle, #000000 1px, rgba(0, 0, 0, 0) 1px);
      // transform: skewY(-11deg);
    }
  }
  thead {
    tr {
      td {
        border-right: unset;
      }
    }
  }
  .fc-col-header {
    tbody {
      tr {
        td {
          border-left: unset;
        }
      }
    }
  }
  .fc-scrollgrid-section-header {
    .fc-resource {
      border-right: unset;
      border-left: unset;
    }
  }
  .fc-scrollgrid-liquid {
    border-top: unset !important;
    // width: 1600px;
    // overflow-y: hidden;
  }
  .fc-scrollgrid-section-header {
    .fc-scrollgrid-sync-inner {
      .fc-col-header-cell-cushion {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        height: 100%;
        align-items: center;
        padding: 10px;
        font-family: $font-regular;
      }
    }
  }
  .fc-timegrid-col{
    height: 2220px;
    // border-right: 1px solid rgb(73, 73, 73);
  }
}

// fc-timeGridWeek-view
.fc-timeGridWeek-view {
  .fc-day{
    .fc-non-business{
      // border-right: 1px solid grey;
      // background-size: 10px 10px;
      // background-image: radial-gradient(circle, #000000 1px, rgba(0, 0, 0, 0) 1px);
      // transform: skewY(-11deg);
      // background-image: repeating-linear-gradient(45deg, grey 3px, transparent 5px, transparent 10px);
      background-image: repeating-linear-gradient(45deg, #bbbbbb 0px, transparent 2px, transparent 7px);  
    }
  }
  .fc-col-header-cell-cushion {
    background-color: unset;
    color: #a4a4a4;
    &:hover {
      color: #000;
      cursor: pointer;
    }
  }
  .fc-col-header-cell-cushion {
    color: #a4a4a4;
  }
  .fc-scrollgrid-section-header {
    .fc-day-today {
      // background-color: #0b586d;
      .fc-col-header-cell-cushion {
        background-color: #0b586d;
        color: #ffffff;
        padding: 4px 12px;
      }
    }
  }
  .fc-scrollgrid-section-body {
    .fc-day-today {
      background-color: transparent !important;
      .fc-col-header-cell-cushion {
        background-color: #0b586d;
        color: #ffffff;
      }
    }
  }
  .fc-timegrid-col{
    height: 2220px;
    // border-right: 1px solid rgb(73, 73, 73);
  }
}

// fc-dayGridMonth-view
.fc-dayGridMonth-view {
  .fc-col-header-cell-cushion {
    background-color: unset;
    color: #a4a4a4;
    &:hover {
      color: #000;
      cursor: pointer;
    }
  }
  .fc-daygrid-day {
    background-color: transparent !important;
  }
  .fc-day-today {
    // background-color: #0b586d;
    .fc-col-header-cell-cushion {
      background-color: #0b586d;
      color: #ffffff;
      padding: 4px 12px;
    }
  }
  .fc-daygrid-event {
    word-break: break-word;
    white-space: unset;
  }
  .fc-col-header-cell-cushion {
    color: #a4a4a4;
  }
}
  // ======================Full calendar styles ends ==========================











  .calendar-popup {
    width: 180px;
    height: 80px;
    border-radius: 7px;
    background-color: #0b586d;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
    .arrow::after {
      border-bottom-color: #0b586d !important;
    }
    // .bs-popover-auto[x-placement^="right"] > .arrow::after,
    // .bs-popover-right > .arrow::after {
    //   border-bottom-color: #0b586d !important;
    // }
    .circle {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: #ffffff;
      border-radius: 50%;
      top: 6px;
      right: 6px;
      &:hover {
        width: 14px;
        height: 14px;
      }
      img {
        width: 6px;
        height: 6px;
        margin: auto;
        &:hover {
          width: 8px;
          height: 8px;
        }
      }
    }
    .popover-body {
      color: #ffffff;
      display: flex;
      padding-left: 15px;
      padding-right: 10px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 68px;
      cursor: pointer;
      img {
        margin-right: 4px;
        width: 19px;
      }
      .align-items-center {
        &:hover {
          color: #efd6d6;
          font-size: 15px;
        }
      }
    }
  }  

.modal-content {
  .example-custom-input {
    background-color: #f6f6f6;
  }
  .css-6iiga6-container {
    background-color: #ffffff;
    // color: #6d6d6d !important;
    // border: 1px solid #e6e6e6;
    height: 57px;
    // box-shadow: 4px 2px 10px 0 rgba(0, 0, 0, 0.1);
  }
  .Block-Slot-form{
    .footer{
      justify-content: end;
    }
  }
}
.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

.dropdown-toggle::after {
  height: 10px;
  width: 10px;
  border-left: 2px solid #a4a4a4;
  border-bottom: 2px solid #a4a4a4;
  transform: rotate(317deg);
  right: 15px;
  bottom: 26px;
  position: absolute;
  border-right: unset !important;
  border-top: unset !important;
}
.css-l10qz9-indicatorContainer {
  .css-tj5bde-Svg {
    fill: #a4a4a4;
  }
}
.custom-dropdown {
  & > div {
    .custom-dropdown-select__indicators {
      span {
        height: 10px !important;
        width: 10px !important;
        border-left: 2px solid #a4a4a4;
        border-bottom: 2px solid #a4a4a4;
        transform: rotate(317deg);
        right: 15px;
        top: 3px;
        position: absolute;
        background-color: unset !important;
      }
    }
    .custom-dropdown-select__control{
      box-shadow: 0 0 0 0px rgb(173, 173, 173) !important;;
      & :hover{
        border-color: rgb(43, 43, 43) !important;
      }
    }
    .custom-dropdown-select__control--is-focused{
      border-color:rgb(165, 164, 164) !important;
    }
  }
    
}

// ======= Staff dropdown overlay starts========
.staff-profile-filter {
  animation-name: fade-in;
	animation-fill-mode: both;
	animation-duration: .5s;
  z-index: 2;
  width: 200px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 3px 4px 21px 2px rgba(187, 187, 187, 0.251);
  border-radius: 10px;
  .popover-body {
    padding-inline: unset;
  }
  .css-q4ifmu-control {
    box-shadow: 0px 3px 6px #8282821a !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px !important;
    border-style: unset !important;
    color: #6d6d6d !important;
    border: 1px solid #e6e6e6 !important;
  }
  .custom-dropdown {
    padding-inline: 20px;
    .btn {
      box-shadow: 0px 3px 6px #8282821a !important;
      border: 1px solid #d9d9d9 !important;
    }
  }

  .dropdown-menu {
    overflow-y: hidden;
    width: 100%;
  }

  .filter-staff {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .css-6iiga6-container {
      // height: 57px;
    }

    .filter-apply {
      width: 50%;
      background-color: #0b586d;
      align-self: center;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 40px;
    }
  }
}
// ======= Staff dropdown overlay starts ==========

// ====== Filters overlay starts  =======
.small-scr-filters-overlay {
  animation-name: right-left;
  // animation-fill-mode: both;
  animation-duration: 0.5s;
  z-index: 9999;
  width: 300px;
  top: -130px !important;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 3px 4px 21px 2px rgba(187, 187, 187, 0.251);
  border-radius: 10px;
  .popover-body {
    padding-inline: unset;
  }
  .css-q4ifmu-control {
    box-shadow: 0px 3px 6px #8282821a !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px !important;
    border-style: unset !important;
    color: #6d6d6d !important;
    border: 1px solid #e6e6e6 !important;
  }
  .right-drawer-cont{
    width: 100%;
    height: 100vh;
    .right-drawer-header{
      height: 30px;
      text-align: end;
      padding: 0px 20px;
      cursor: pointer;
    }
    .right-drawer-content{
      height: 100vh;
      overflow-y: scroll;
      padding-bottom: 100px;
      .select-view-cont{
        padding: 20px;
        .select-view-heading{
          font-weight: 900;
          font-size: 20px;
          font-family: $font-bold;
        }
        .views-list-cont{
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .view{
            width: 120px;
            background-color: aliceblue;
            border-radius: 30px;
            height: 40px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-regular;
            cursor: pointer;
            &:hover{
              background-color: rgb(239, 240, 240);
              color: black;
            }
          }
          .view-active{
            background-color: #2b27f3;
            color: white;
          }
        }
      }
      .members-cont{
        padding-top: 20px;
        .members-heading{
          padding-left: 20px;
          font-weight: 900;
          font-size: 20px;
          font-family: $font-bold;
        }
        .members-list-cont{
          padding-top: 20px ;
          .members-list{
            list-style-type: none;
            margin: 0;
            .members-list-item{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              padding: 20px;
              border-bottom: 1px solid #d1d1d1;
              cursor: pointer;
              &:hover{  
                background-color: rgb(239, 240, 240);
              }
              .member-img-container{
                height: 40px;
                width: 40px;
                margin-right: 10px;
                img{
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                }
                .profile-text{
                  width: 40px;
                  height: 40px;
                }
              }
              p{
                font-size: 16px;
                font-weight: 700;
                font-family: $font-regular;
                overflow: hidden;
                white-space: nowrap;
              }
            }
            
            .members-list-item-active{
              background-color: aliceblue;
            }
          }
        }
  
      }  
    }
    .right-drawer-content::-webkit-scrollbar{
      display: none;
    }
    
  }
  @media (max-width: 437px) {
    top: -200px !important;
  }
}

//========== Add appointment overlay starts =============
.add-appt-overlay {
  animation-name: fade-in;
	animation-fill-mode: both;
	animation-duration: .5s;
  z-index: 2;
  width: fit-content;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 2px 2px 20px 8px rgba(48, 48, 48, 0.25);
  border-radius: 2px;
  top: 5px !important;
  left: -10px !important;
  .popover-body {
    padding-inline: unset;
  }
  .add-appointment-content{
    padding: 0px 10px;
    .add-appointment-items{
      .add-appointment-item{
        padding: 10px 0px;
        p{
          cursor: pointer;
          font: normal normal 600 16px/16px Nunito Sans;
          letter-spacing: 0px;
          color: #222222;
        }
      }
    }

  }
}
//========== Add appointment overlay ends =============


//fade-in animation key frames
$animationDelay: 1;
@keyframes fade-in {
  0% { margin-top: -20px; opacity: 0;}
  100% { margin-top: 0.5rem; opacity: 1; }
}

//fade-up animation keyframes
@keyframes fade-up {
  0% { bottom: 0px; opacity: 0;}
  100% { bottom: 50px; opacity: 1; }
}

//right drawer animation
@keyframes right-left {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
