@import "../../styles/TextStyles.scss";

.table-wrapper {
  margin-top: 25px;
  .search-wrap {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    height: auto;
    .promo-offer-tabs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      height: auto;
      .offers-cont{
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E6EEF0 0% 0% no-repeat padding-box;
        border-radius: 491px;
        margin-right: 20px;
        color: #000000;
        font-family: $font-regular;
        font-size: 20px;
        cursor: pointer;
        letter-spacing: 0px;
      }
      .promo-cont{
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E6EEF0 0% 0% no-repeat padding-box;
        border-radius: 491px;
        color: #000000;
        font-family: $font-regular;
        cursor: pointer;
        letter-spacing: 0px;
        font-size: 20px;
        margin-right: 20px;
      }
      .active{
        background-color: #0B586D;
        color: #FFFFFF;
      }
      @media (max-width: 500px) {
        height: 130px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    }
    .cta-col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      .primary-button-container {
        width: 142px;
        border-radius: 7px;
      }
    }
    @media (max-width: 650px) {
        flex-direction: column;      
        justify-content: flex-start;
        align-items: flex-start;
        height: 120px;
    }
    @media (max-width: 500px) {
        height: 200px;
    }
  }

  .offer-promo-list-cont{
    padding: 10px;
    max-width: 1200px;
    margin: auto;
    .offer-promo-list-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #DCDCDC;
      border-radius: 7px;
      margin-bottom: 10px;
      padding: 20px 30px;
      cursor: pointer;
      &:hover{
        background: #F6F6F6 0% 0% no-repeat padding-box;
      }
      .left-sec{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .heading{
          font-size: 15px;
          font-family: $font-bold;
          letter-spacing: 0;
          color: #000000;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          p{
            cursor: pointer;
          }
          width: 95%;
          @media (max-width:576px) {
            flex-direction: column;
          }
        }
        
        .content{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .value-cont{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @media (max-width:576px) {
              flex-direction: column;
              align-items: flex-start;
            }
            .text{
              font-family: $font-regular;
              font-size: 14px;
              color:  #818181;
              letter-spacing: 0;
            }
            .value{
              font-family: $font-bold;
              font-size: 14px;
              color:  #000000;
              letter-spacing: 0;
            }
          }

          @media (max-width: 700px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
        }
      }
      .right-sec{
        height: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
          cursor: pointer;
        }
      }
      @media (max-width: 580px) {
        height: fit-content;
        .left-sec{
          padding-right: 15px;
        }
      }
    }
  }

  .note-cont{
    max-width: 1200px;
    margin: auto;
    padding: 50px 20px 0px 20px;
    margin-top: 50px;
    .note-content{
      padding: 20px;
      width: 100%;
      height: fit-content;
      border: 2px solid #d3d1d1;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .header-cont{
        letter-spacing: 0px;
        color: #000000;
        font-family: $font-bold;
        font-size: 19px;
      }
      .text-cont{
        letter-spacing: 0px;
        color: #000000;
        font-family: $font-regular;
        font-size: 19px;
      }

    }
  }
}

//offer-promo-menu-overlay starts
.offer-promo-menu-overlay{
  animation-name: fade-in;
  animation-duration: 0.5s;
  z-index: 9999;
  width: 150px;
  // height: 90px;
  margin-left: -60px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 10px #A7A7A740;
  border-radius: 4px;
  .popover-body {
    padding-inline: unset;
  }
  .offer-promo-menu-cont{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 20px;

    .edit-cont{
      padding-bottom: 5px;
      cursor: pointer;
    }
    .delete-cont{
      padding-bottom: 5px;
      color: red;
      cursor: pointer;

    }
  }
}
//offer-promo-menu-overlay ends

.swal2-container{
  .swal2-show{
    animation: swal2-show 0.6s !important;
  }
  .swal2-popup{
    height: fit-content !important;
  }
}

// select discount type modal start
.select-discount-type-modal{
  .modal-dialog{
    min-width: fit-content;
  }
  .modal-content{
    max-width: fit-content;
    max-height: fit-content;
    min-width: fit-content;
  }
  .modal-header{
    padding: 10px 20px !important;
    align-items: center !important;
    .modal-title{
      font-size: 20px !important;
      font-family: $font-bold !important;
      letter-spacing: 0 !important;
    }
    .close{
      font-size: 30px !important;
    }
  }
  .select-discount-type-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 40px 80px;
    .select-discount-type-cont{
      display: flex;
      align-items: center;
      .offers-cont{
        width: 271px;
        height: 325px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #D4D4D4;
        border-radius: 7px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        padding: 45px;
        cursor: pointer;
        &:hover{
           border: 1px solid #0B586D;
        }
      }
      .promo-cont{
        width: 271px;
        height: 325px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #D4D4D4;
        border-radius: 7px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 45px;
        cursor: pointer;
        &:hover{
          border: 1px solid #0B586D;
       }
      }
      .ico-cont{
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
      }
      .heading-cont{
        font-size: 19px;
        font-family: $font-regular;
        letter-spacing: 0;
        color: #000000;
        padding-bottom: 10px;
      }
      .desc-cont{
        font-size: 14px;
        font-family: $font-regular;
        letter-spacing: 0;
        color: #858585;
        padding-bottom: 10px;
      }
      @media (max-width: 800px) {
        flex-direction: column;
        .offers-cont{
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
  
}
// select discount type modal ends


// Add/Edit offer modal styles start
.add-offer-modal{
  .modal-content{
    height: fit-content;
    max-width: 700px;
  }
  .modal-header{
    padding: 10px 30px !important;
    align-items: center !important;
    .modal-title{
      font-size: 20px !important;
      font-family: $font-bold !important;
      letter-spacing: 0 !important;
    }
    .close{
      font-size: 30px !important;
    }
  }
  .add-offer-content{
    padding: 20px;
    .offers-form {
      padding: 0 !important;
      .modal-body{
        padding: 0 !important;
        .value-type-cont{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .label-cont{
            p{
              font-family: "Nunito Sans-bold";
              font-size: 15px;
              color: #1d1d1d;
              margin-bottom: 13px;
            }
          }
          .items-cont{
            display: flex;
            align-items: center;
            justify-content: center;
            .value-type-item{
              border: 1px solid #D4D4D4;
              border-radius: 4px;
              width: 64px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            .value-type-active{
              background: #0B586D 0% 0% no-repeat padding-box;
              color: white;
            }
          }
        }
        .display-cb-cont{
          .label-cont{
            height: 35px;
            display: block;
            @media (max-width: 768px) {
              display:none;
            }
          }
          .checkbox-label {
            height: 57px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            .custom-checkbox {
              margin: unset;
              padding-right: 15px;
            }
            .form-label{
              margin: unset;
            }
            .info-ico-cont{
              width: 15px;
              height: 15px;
            }
          }
        }
        .validity-cont{
          .label-cont{
            height: 35px;
            p{
              font-size: 15px;
              color: #1d1d1d;
              font-family: $font-bold;
            }
          }
          .date-cont{
            height: 60px;
            .react-datepicker-wrapper{
              width: 100%;
              height: 60px;
              padding: 3px 10px 0px 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #FFFFFF 0% 0% no-repeat padding-box;
              border: 1px solid #D4D4D4;
              border-radius: 4px;
              .example-custom-input{
                padding: unset;
                width: 100% !important;
                margin-left: 0 !important;
                white-space: nowrap ;
                font-size: 19px;
                font-weight: normal;
                color: grey;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @media (max-width: 992px) {
                  font-size: 15px;
                }
              }
              &::before{
                display: none;
              }
              &:hover{
                border: 1px solid #b9b6b6;
              }
            }
          }
          
        }
        .image-wrapper{
          padding-left: 15px;
          img{
            width: 100%;
            height: 200px;
          }
        }
        // .react-datepicker-popper{
        //   // background-color: white;
        //   z-index: 999;
        //   animation-name: fade-in;
        //   animation-fill-mode: both;
        //   animation-duration: .5s;
        // }
      }
      .footer {
        justify-content: space-between;
        padding: 40px 10px 0px 10px !important;
        .checkbox-label{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: self-start;
          height: 20px;
          .custom-checkbox{
            margin: 0 !important;
          }
          small{
            padding-left: 30px;
          }
        }
        button {
          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
        .save-btn {
          background-color: #0b586d;
        }
      }
      .display {
        font-family: "Nunito Sans-bold";
        font-size: 15px;
        color: #1d1d1d;
      }
  }
}
// Add/Edit offer modal styles ends

@keyframes fade-in {
  0% { margin-top: -20px; opacity: 0;}
  100% { margin-top: 0; opacity: 1; }
}










// .custom-input {
//   margin-bottom: 0;
// }
// .row {
//   margin-top: 15px;
// }
// .checkbox-label {
//   display: flex;
//   flex-wrap: wrap;
//   .custom-checkbox {
//     margin-top: unset;
//   }
//   small {
//     margin-top: 3px;
//   }
// }
// .image-wrapper {
//   img {
//     width: 100%;
//   }
//   input {
//     margin: 15px 0;
//   }
// }


}
//Add/Edit offer modal styles end


//select services modal start
.staff-select-service-popup {
  max-height: 90vh;
  .modal-header {
    padding: 21px 32px;
    .modal-title {
      font-size: 19px;
      font-family: $font-bold;
    }
  }

  .modal-body {
    padding: 0;
    .search-input {
      margin: 20px 40px;
    }
    ul {
      list-style: none;
      .no-service-container {
        text-align: center;
      }
      li {
        padding: 20px 40px;
        border-top: 1px solid #d6d6d6;

        label {
          font-size: 19px;
          font-family: $font-bold;
          input {
            margin-right: 10px;
            height: 20px;
            width: 20px;
            vertical-align: middle;
          }
          small {
            font-size: 15px;
            color: #929292;
            font-family: $font-semibold;
            &.second {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
  .modal-footer {
    padding: 21px 32px;
    border: none;
    .secondary-button-container {
      margin-right: 25px;
    }
  }
}
//select services modal ends


// .labels {
//   margin-top: 20px;
//   background-color: #00a65a;
//   color: #fff;
//   padding: 15px;
//   border-radius: 4px;
//   img {
//     margin-right: 5px;
//     margin-bottom: 2px;
//   }
// }
// .pagination {
//   justify-content: space-between;
//   align-items: center;
//   .result-text {
//     color: #6d6d6d;
//     font-size: 15px;
//     @media screen and (max-width: 480px) {
//       text-align: center;
//       margin-bottom: 10px;
//     }
//     @media screen and (max-width: 767px) {
//       margin-top: 10px;
//     }
//   }
//   .count {
//     display: flex;
//     width: 100%;
//     cursor: pointer;
//     justify-content: flex-end;
//     @media screen and (max-width: 480px) {
//       justify-content: center;
//     }
//     @media screen and (max-width: 767px) {
//       margin-top: 10px;
//       margin-bottom: 20px;
//     }
//     .previous,
//     .next {
//       min-width: 40px;
//       height: 40px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       cursor: pointer;
//       background: #ffffff 0% 0% no-repeat padding-box;
//       border: 1px solid #d9d9d9;
//       border-radius: 100%;
//     }
//     .previous {
//       margin-right: 20px;
//       @media (max-width: 400px) {
//         margin-right: 5px;
//       }
//     }
//     .next {
//       margin-left: 20px;
//       @media (max-width: 400px) {
//         margin-left: 5px;
//       }
//     }
//     .pages {
//       height: 40px;
//       background: #ffffff 0% 0% no-repeat padding-box;
//       border: 1px solid #d9d9d9;
//       border-radius: 30px;
//       font-size: 14px;
//       color: #989898;
//       padding: 6px 15px;

//       justify-content: space-evenly;
//       span {
//         vertical-align: middle;
//         padding: 11px 10px;
//         &.active {
//           color: #ffffff;
//           background: #0b586d;
//           border-radius: 5px;
//         }
//       }
//     }
//   }
// }
// .spinner-wrap {
//   margin-top: 15px;
// }

// .modal-90w {
//   @media (min-width: 576px) {
//     max-width: 599px;
//   }
//   @media (min-width: 992px) {
//     max-width: 800px;
//   }
// }
.offers {
  // .custom-input-search {
  //   padding-left: unset !important;
  // }
}
