.administration {
  .heading-row {
    align-items: flex-end;
    margin-top: 25px;
    margin-bottom: 20px;
    .custom-input-search {
      padding-bottom: unset;
    }
  }
  .react-bootstrap-table table {
    table-layout: unset;
    th {
      padding: 10px;
      vertical-align: top;
    }
    td {
      padding: 10px;
    }
  }
}
