.incomplete-profile-status-cont {
	padding: 40px;
	.profile-completion-body {
		.profile-completion-body-cont {
			display: flex;
			align-items: center;
			flex-direction: column;

			.profile-completion-header {
				padding-bottom: 20px;

				p {
					font: normal normal bold 22px/20px Nunito Sans;
					letter-spacing: 0px;
					color: #000000;
				}
			}

			.img-cont {
				padding-bottom: 20px;
			}

			.profile-completion-desc1 {
				padding-bottom: 20px;

				p {
					font: normal normal bold 18px/20px Nunito Sans;
					letter-spacing: 0px;
					color: #000000;
				}
			}

			.profile-completion-desc2 {
				padding-bottom: 20px;
				text-align: center;

				p {
					font: normal normal normal 18px/28px Nunito Sans;
					letter-spacing: 0px;
					color: #000000;
					opacity: 0.48;
				}
			}
		}
	}

	.profile-completion-footer {
		padding-top: 50px;

		.profile-completion-footer-cont {
			display: flex;
			justify-content: space-between;

			.profile-completion-do-later {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				font: normal normal bold 20px/28px Nunito Sans;
				letter-spacing: 0px;
				color: #0B586D;
				opacity: 1;
			}

			.profile-completion-lets-do {
				display: flex;
				justify-content: center;
				align-items: center;
				background: #0B586D 0% 0% no-repeat padding-box;
				box-shadow: 0px 3px 6px #9E9E9E29;
				border-radius: 5px;
				opacity: 1;
				padding: 10px 20px;
				cursor: pointer;

				p {
					font: normal normal medium 15px/23px Poppins;
					letter-spacing: 0px;
					color: #FFFFFF;
				}
			}
		}
	}
}