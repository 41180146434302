@import "../../../styles/TextStyles.scss";

.partnersSummaryReport {
  .no-data-text {
    text-align: center;
  }

  .filtersCont {
    margin: 0;

    .primary-button-container {
      margin-top: 40px !important;

      @media (max-width: 576px) {
        margin-top: 1px !important;
      }
    }
  }

  .mainContent {
    margin: 0px;
    margin-top: 20px;

    .topRowCont {
      div {
        // padding: 0;
      }

      .headingCont {
        margin-right: 25px;
        height: 80px;
        background-color: #dadada;
        display: flex;
        align-items: center;
        border-radius: 10px;

        p {
          padding-left: 30px;
          font-size: 30px;
          font-family: $font-extrabold;

          @media (max-width: 992px) {
            font-size: 20px;
            font-family: $font-extrabold;
          }
        }

        @media (max-width: 768px) {
          margin-bottom: 15px;
          margin-right: 0px;
        }
      }

      .totalCont {
        padding: 0;
        height: 80px;
        background-color: #8d7551;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px 0px 20px;
        color: white;
        border-radius: 10px;

        .num {
          font-size: 30px;
          font-family: $font-extrabold;

          @media (max-width: 992px) {
            font-size: 20px;
          }
        }

        .text {
          font-size: 15px;
          font-family: $font-extrabold;
          padding-left: 15px;
        }

        @media (max-width: 768px) {
          margin-bottom: 15px;
        }
      }

      .freelancer {
        background-color: #d5b27a;
      }
    }

    .graphsCont {
      .multiGraphContent {
        width: 100%;
        height: 400px;
        background-color: #dadada;
        margin-top: 15px;
        border-radius: 10px;
        padding: 20px;

        /* Center-align data labels in the chart */
        .center-align-data-labels {
          .chartjs-datalabels {
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;
          }

          /* Optionally, you can style the label text itself */
          .center-align-data-labels .chartjs-datalabels-label {
            font-weight: bold;
            color: black;
          }
        }



      }

      .barGraphContent {

        .bottomSecContent {
          width: 100%;
          height: 400px;
          background-color: #dadada;
          margin-top: 15px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 10px;

          p {
            font-size: 15px;
            font-family: $font-extrabold;
          }

          .barGraphCont {
            height: 100%;
            width: 250px;

            @media (max-width: 992px) {
              width: 180px;
            }
          }
        }
      }
    }
  }

}