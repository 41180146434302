@import "../../../styles/TextStyles.scss";


.day{
  .mbsc-calendar-wrapper{
    display: none;
  }
  .mbsc-schedule-header-item{
    display: none;
  }
}
.week{
  .mbsc-calendar-wrapper{
    display: none;
  }
  .mbsc-schedule-resource{
    display: none;
  }
}
.month{
  .mbsc-calendar-header{
    display: none;
  }
}

.mbsc-calendar{
  border: 1px solid #cfcfcf;
 
  .mbsc-schedule-date-header{
    display: none;
  }
  .mbsc-schedule-grid-scroll::-webkit-scrollbar{
    display: none;
  }
  .mbsc-schedule-col-width{
    width: 100px;
  }

  .mbsc-schedule-header{
    background-color: white;
    border-bottom: none;
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .mbsc-schedule-time-col-empty{
      border-right: 1px solid #c3bfbf;
      // border-bottom: 1px solid #c3bfbf;
      border-bottom: none;
    }
    .mbsc-schedule-header-wrapper{
      .header-resource-template-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
          width: 46px;
          height: 46px;
          border-radius: 50%;
          border: 2px solid white;
        }
        .header-resource-title-cont{
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          .title-border{
            width: 50px;
            height: 50px;
            border: 2px solid #0B586C;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .header-resource-title{
            font-size: 24px;
            font-weight: 800;
            color: #0b586d;
            background-color: #cde4ff;
            text-transform: uppercase;
            cursor: pointer;
            width: 46px;
            height: 46px;
            border: 2px solid white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-extrabold;
          }
        }
        .header-resource-name{
          white-space: nowrap;
          color: #0b586d;
          font-size: 12px;
          width: 80%;
          overflow: hidden;
          &:hover {
            color: black;
          }
        }
        
      }
      .mbsc-ios.mbsc-schedule-resource.mbsc-ltr{
        border: none;
      }
    }
  }
  
  .mbsc-schedule-grid-wrapper{
    .mbsc-schedule-time-col{
      // margin-top: -780px;
      border-right: 1px solid #f5f5f5;
      .mbsc-schedule-time-wrapper{
        height: 25px;
        .mbsc-schedule-time{
          color: #000000;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .mbsc-schedule-grid-scroll{
      // background-color: #f5f5f5;
      .mbsc-schedule-column{
        padding-top: 0;
        .mbsc-schedule-column-inner{
          .mbsc-schedule-invalid-start{
            background-image: repeating-linear-gradient(45deg, #bbbbbb 0px, transparent 2px, transparent 7px);  
          }
          .mbsc-schedule-invalid-end{
            background-image: repeating-linear-gradient(45deg, #bbbbbb 0px, transparent 2px, transparent 7px);  
          }
          .mbsc-schedule-invalid{
            background-image: repeating-linear-gradient(45deg, #bbbbbb 0px, transparent 2px, transparent 7px);  
          }
          .mbsc-schedule-item{
            height: 25px;
            border-color: #dfdfdf;
            &:hover{
              background-color: rgb(192, 223, 233);
              color: black;
            }
          }
        }
        
      }
    }
  }
}




// ==================== Event  Tooltip popup starts
.md-tooltip .mbsc-popup-content {
  padding: 0;
}
.mbsc-popup {
  width: 220px !important;
}
.md-tooltip {
  font-size: 15px;
  font-weight: 600;
}
.md-tooltip-header {
  padding: 5px 16px;
  color: black;
}
.md-tooltip-info {
  padding: 16px 16px 16px 16px;
  position: relative;
  // line-height: 32px;
}
.md-tooltip-time,
.md-tooltip-status-button {
  float: right;
}
.md-tooltip-title {
  margin-bottom: 10px;
}
.md-tooltip-text {
  font-weight: 600;
  font-size: 13px;
}
.md-tooltip-info .mbsc-button {
  font-size: 14px;
  margin: 0;
}
.md-tooltip-info .mbsc-button.mbsc-material {
  font-size: 12px;
}
.md-tooltip-view-button {
  margin-top: 30px;
  right: 0px;
}
.md-tooltip-delete-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.md-tooltip-action-cont{
  display: flex;
  justify-content: end;
}
// ==================== Event  Tooltip popup ends
