.sellers-listing {
  margin-top: 10px;
  
  .heading-row {
    justify-content: space-between;
    b{
      font-size: 25px;
    }
    .custom-input-search {
      padding: 10px;
      .input-group {
        border-radius: 154px;
        height: 41px;
        .input-group-text {
          padding-top: unset;
        }
      }
    }
    .cta-col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-right: 28px;
      @media screen and (max-width: 480px) {
        margin-top: 15px !important;
      }
      .primary-button-container {
        width: 142px;
        border-radius: 7px;
      }
    }
  }

  .spinner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-left: 10px;
    }
  }
  .no-data-text {
    text-align: center;
  }
}