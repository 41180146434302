@import "../../styles/TextStyles.scss";

.new-sidear{
  width: 70px;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 0px 10px #0B586D0D;
  opacity: 1; 
  @media (max-width: 500px) {
    width: 60px;
  }
  .logo-cont{
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo{
      width: 60px;
      cursor: pointer;
    }
  }
  .logo-border{
    border-bottom: 5px solid #0B586D;
    opacity: 0.05;
    width: 70px;
    height: 0px;
  }
  .menu-cont{
    width: 70px;
    height: calc(100vh - 125px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      margin-top: 20px;
      list-style: unstyled;
      li {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        font-size: 15px;
        font-family: $font-semibold;
        color: #b9b9b9;
        margin-bottom: 10px;
        &.active {
          a {
            background: #0B586D 0% 0% no-repeat padding-box !important;
            border-radius: 10px;
            // color: #0b586d;
            font-family: $font-bold;
          }
        }
  
        a {
            width: 50px;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-family: $font-semibold;
          // color: #b9b9b9;
          .nav-icon {
            width: 30px;
            height: 30px;
          }
          &.active {
            // color: #0b586d;
            svg {
              // fill: #0b586d;
            }
          }
          &:hover {
            text-decoration: none;
            background: #F2F6F7 ;
            border-radius: 10px;
          }
        }
        .sub-links-section{
          .setting-header{
            padding: 10px 5px;
            .setting-icon{
              height: 30px;
            }
          }
        }
        .sub-links-wrap {
          padding: 5px;
          .active-link {
            background: #0B586D 0% 0% no-repeat padding-box !important;
            border-radius: 10px;
          }
        }
      }
    }

  }
  .menu-cont::-webkit-scrollbar {
    width: 5px;
    display: none;
  }
  /* Track */
  .menu-cont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  /* Handle */
  .menu-cont::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
  }
  /* Handle on hover */
  .menu-cont::-webkit-scrollbar-thumb:hover {
    background: grey; 
  }
  .footer-cont{
    height: 25px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}























.side-menu {
  overflow: auto;
  padding: 20px 30px;
  background-color: #fff;
  height: 100vh;
  max-width: 269px;
  transition: all 0.3s ease-out;
  .sub-links-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    .setting-header {
      height: 22px;
      .img {
        width: 30px;
        cursor: pointer;
        height: 30px !important;
      }
    }
    img {
      max-width: 20px;
      display: inline;
      margin-right: 15px;
    }
    .nav-icon {
      width: 15px;
      height: 15px;
      border: 1px solid #b9b9b9;
      border-radius: 50%;
    }
    span {
      cursor: pointer;
    }
    .sub-links-wrap {
      padding: 5px;
      a {
        margin: 12px 0 12px 5px;
      }
      .active-link {
        color: #0b586d;
      }
    }
  }
  @media screen and (max-width: 480px) {
    transform: translateX(-100%);
    position: absolute;
    left: 0;
    z-index: 9999;
  }
  &.collapse-panel {
    max-width: 100px;

    .nav-text {
      display: none;
    }
    ul {
      li {
        transition: all 0.3s ease-out 0.5s;
        justify-content: center;
        background-color: red;
        a {
          .nav-icon {
            margin-right: 0px;
          }
        }
        .sub-links-section {
          position: relative;
          left: 9px;
          .sub-links-wrap {
            visibility: hidden;
          }
        }
      }
    }
  }
  &.show {
    transform: translateX(0%);
  }
  .logo-header {
    min-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .panel-icon {
      width: 35px;
      height: auto;
      cursor: pointer;
      display: none;
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
    .logo {
      // margin-left: 50px;
      width: 50px;
    }
  }

  ul {
    margin-top: 80px;
    list-style: unstyled;
    li {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      font-size: 15px;
      font-family: $font-semibold;
      color: #b9b9b9;
      margin-bottom: 30px;
      &.active {
        a {
          color: #0b586d;
          font-family: $font-bold;
          &:after {
            content: "";
            background-color: #0b586d;
            right: 0;
            width: 5px;
            position: absolute;
            height: 25px;
          }
        }
      }

      a {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        font-size: 15px;
        font-family: $font-semibold;
        color: #b9b9b9;
        .nav-icon {
          margin-right: 15px;
          width: 20px;
          height: 20px;
        }
        &.active {
          color: #0b586d;
          svg {
            fill: #0b586d;
          }
        }
        &:hover {
          text-decoration: none;
          color: #0b586d;
        }
      }
    }
  }
  .tip {
    min-height: 252px;
    background-color: #bca086;
    margin-top: auto;
    box-shadow: 0px 1px 7px #0000002e;
    border-radius: 6px;
    text-align: center;
    padding: 20px;
    overflow: hidden;
    img {
      width: 32px;
      height: auto;
    }
  }
  footer {
    text-align: center;
    color: #b9b9b9;
  }
}

.side-menu::-webkit-scrollbar {
  width: 5px;
  // display: none;
}

/* Track */
.side-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.side-menu::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 10px;
}

/* Handle on hover */
.side-menu::-webkit-scrollbar-thumb:hover {
  background: grey; 
}
