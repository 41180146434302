.bank-account-form {
  margin-bottom: 20px;
  margin-top: 25px;
  .mt {
    margin-top: unset;
    .col-md-6,
    .col-md-12 {
      margin-top: 15px;
    }
  }
}
.save-account {
  text-align: end;
  margin-top: 25px;
  .btn {
    justify-content: space-between;
    background-color: #0b586d;
  }
}
