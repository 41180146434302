@import "../../styles/TextStyles.scss";

.example-custom-input {
  cursor: pointer;
  padding: 5px 15px;
  border: 0;
  border-radius: 4px;
  background-color: #fff;
  font: inherit;
  color: #101928;
  width: 100%;
  height: 35px;
  font-weight: bold;
}
.add-appointment {
  h2 {
    text-align: center;
    font-size: 22px;
    font-family: $font-bold;
    background-color: #f6f6f6;
    padding: 20px;
  }
  .main-container {
    height: calc(100vh - 60px);
    .left-block {
      position: relative;
      margin-top: 7px;
      height: calc(100vh - 100px);

      @media screen and (min-width: 768px) {
        padding-right: unset;
      }
      @media screen and (min-width: 900px) {
        padding-right: 15px;
      }
      .divider-line {
        position: absolute;
        width: 1px;
        height: 200px;
        left: -20px;
        top: 15px;
        border-left: 1px solid #d9d9d9;
        z-index: -1;
        @media screen and (max-width: 640px) {
          margin-left: 17px;
        }
      }
      .radio-area {
        padding-right: unset;
        right: 2px;
        .radio-group {
          display: flex;
          justify-content: flex-end;
          label {
            font-weight: 600;
          }
        }
      }
      .radio-group-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .react-datepicker-wrapper {
          margin-right: 10px;
          margin-bottom: 8px;
        }
        .react-datepicker {
          font-size: 0.8rem;
          background-color: #fff;
          color: #000;
          border: 1px solid #aeaeae;
          border-radius: 0.3rem;
          display: inline-block;
          position: relative;
          margin-left: -150px;
          @media screen and (max-width: 640px) {
            margin-left: -10px;
          }
        }
        .radio-group {
          margin-bottom: 8px;
          .radio-group {
            display: flex !important;
            justify-content: flex-end;
            .custom-dropdown {
              background-color: #000;
            }
            .no-padding {
              button {
                padding: 0.375rem 0.75rem !important;
                border: unset;
                background-color: unset;
                &:active,
                &:focus,
                &:hover {
                  background-color: unset;
                }
              }
            }
          }
        }
        .partners-dropdown {
          display: flex;
          justify-content: flex-end;
          @media screen and (max-width: 1289px) {
            flex: auto;
          }
          img {
            width: 26px;
          }
          .custom-dropdown {
            width: 163px;
            .dropdown {
              button {
                padding: 0 4px;
                border: unset;
                background-color: unset;
              }
            }
          }
        }
      }
      .secondary-button-container {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 20px;
      }
      .primary-button-container {
        display: inline-block;
        margin: auto;
      }
      .service-block {
        display: flex;
        position: relative;
        .mark-start {
          position: absolute;
          text-align: center;
          color: white;
          top: 40%;
          left: -20%;
          width: 22px;
          height: 22px;
          border-radius: 100%;
          background-color: #0b586d;
          z-index: 1;
          .vl {
            border-left: 2px solid #cbcbcb;
            position: absolute;
            left: 50%;
            margin-left: -1px;
            top: 22px;
          }
        }
        .mark-end {
          content: "";
          color: white;
          text-align: center;
          position: absolute;
          width: 22px;
          height: 22px;
          top: 100%;
          left: -20%;
          border-radius: 100%;
          border: 1px solid #d9d9d9;
          background-color: rgba(255, 255, 255, 0);
        }
        .mt-4 {
          flex: 1;
          .col-md-8,
          .col-md-4 {
            padding: 10px;
          }
          @media screen and (min-width: 768px) {
            .col-md-4 {
              padding-right: unset;
            }
          }
          @media screen and (min-width: 900px) {
            .col-md-4 {
              padding-right: 15px;
            }
          }
          input {
            max-height: 57px;
          }
          .dropdown button {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .custom-dropdown {
            .css-6iiga6-container {
              height: 57px;
            }
            &::active {
              height: 57px;
            }
          }
        }
        .warning {
          color: #dc3545;
          font-family: $font-bold;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .btn {
            background-color: #0b586d;
            border-color: #0b586d;
            &:hover,
            &:disabled,
            &:focus,
            &:active {
              background-color: #0b586d !important;
              border-color: #0b586d !important;
            }
            &:hover,
            &:focus {
              box-shadow: 0 4px 12px -3px #0b586d !important;
            }
          }
        }

        .remove-service-button {
          position: absolute;
          margin-top: 1.5rem;
          padding: 0;
          background: unset;
          border: unset;
          margin-bottom: auto;
          z-index: 21;
          right: -3px;
          outline: unset;
          box-shadow: unset;
          z-index: auto;
          top: 5px;
          img {
            max-width: 15px;
            margin-left: -30px;
            @media screen and (max-width: 899px) {
              margin-left: -65px;
            }
            @media screen and (max-width: 769px) {
              margin-left: -50px;
            }
          }
          @media screen and (min-width: 768px) {
            right: -15px;
          }
          @media screen and (min-width: 900px) {
            right: 0;
          }
        }
        .add-service-button {
          position: absolute;
          right: 0;
          bottom: -10%;
          padding: 0;
          background: unset;
          border: unset;
          z-index: 21;
          outline: unset;
          box-shadow: unset;
          z-index: auto;

          img {
            max-width: 15px;
          }

          @media screen and (min-width: 768px) {
            right: -15px;
          }
          @media screen and (max-width: 767px) {
            bottom: -6%;
          }
          @media screen and (min-width: 900px) {
            right: 0;
          }
        }
      }
      .mt-4 {
        .notes {
          margin-top: 10px;
          // @media screen and (min-width: 768px) {
          //   padding-right: unset;
          // }
          @media screen and (min-width: 900px) {
            padding-right: 15px;
          }
        }
      }
      .justify-content-end {
        .col-md-auto {
          @media screen and (min-width: 768px) {
            padding-right: unset;
          }
          @media screen and (max-width: 767px) {
            text-align: end;
          }
          @media screen and (min-width: 900px) {
            padding-right: 15px;
          }
        }
      }
      .empty-2 {
        display: block;
        align-items: center;
        justify-content: center;
        .save-button-side-panel {
          max-width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
        .total {
          font-family: $font-extrabold;
          font-size: 17px;
          color: #000;
          text-align: center;
          margin-top: 15px;
          margin-bottom: 30px;
        }
        .primary-button-container {
          width: 100%;
        }
      }
    }
    /* width */
    .left-block::-webkit-scrollbar {
      width: 1px;
    }
    /* Track */
    .left-block::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    /* Handle */
    .left-block::-webkit-scrollbar-thumb {
      background: #888;
    }
    /* Handle on hover */
    .left-block::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .search-panel {
      padding: 0px;
      height: 100%;
      background: #ffffff 0% 0% no-repeat padding-box;
      .right-container {
        height: 100%;
        .three-dot-icon {
          position: absolute;
          right: 10px;
          // top: 5px;
          cursor: pointer;
        }
        .bottom-sec {
          display: flex;
          height: calc(100% - 80px);
          flex-direction: column;
          justify-content: space-between;
          .empty-1 {
            padding: 34% 0;
            font-family: $font-semibold;
            font-size: 16px;
            color: #959595;
            text-align: center;
          }
          .search-list {
            flex: 1;
            overflow-y: auto;
            margin-bottom: 24px;
            .client-info-block {
              padding: 10px 0;
              height: calc(100% - 77px);
              display: flex;
              flex-direction: column;
              div {
                margin: 5px 10px;
                border-bottom: 1px solid #eef0f2;
              }
              .new-client-btn {
                padding-bottom: 10px;
                text-align: end;
                button {
                  background-color: #007bffa3 !important;
                  border-color: #007bffa3 !important;
                }
              }
              .summary-block {
                display: flex;
                justify-content: space-around;
                div {
                  text-align: center;
                }
              }
              .info-tabs {
                display: flex;
                justify-content: space-around;
                span {
                  cursor: pointer;
                }
              }
              .selected-tab {
                border-bottom: 2px solid #000;
              }
              .tab-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                .no-history-image {
                  max-width: 80px;
                }
                .filled-data {
                  text-align: left;
                  label {
                    font-size: 18px;
                    font-family: $font-semibold;
                    color: #959595;
                    margin: 0;
                    margin-top: 26px;
                  }

                  .response {
                    font-size: 15px;
                    font-family: $font-semibold;
                    color: #000;
                  }
                }
              }
            }
          }
           /* width */
          .search-list::-webkit-scrollbar {
            width: 1px;
          }
          /* Track */
          .search-list::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          /* Handle */
          .search-list::-webkit-scrollbar-thumb {
            background: #888;
          }
          /* Handle on hover */
          .search-list::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          .customer-details-wrapper {
            display: flex;
            align-items: center;
            padding: 8px 24px;
            cursor: pointer;
            border-bottom: 1px solid #eef0f2;
            img {
              border-radius: 50%;
              width: 60px;
              height: 60px;
              margin-right: 10px;
            }
            p {
              text-transform: capitalize;
            }
          }
        }
        .custom-input-search {
          padding-bottom: 10px !important;
        }
      }
    }
  }
  .cancel-icon {
    cursor: pointer;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 99999;
  }
}
.remove-selected-item {
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
}
