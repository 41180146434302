.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 26px;
  margin-bottom: 0px;
}
.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #a385692d;
  cursor: pointer;
  transition: all 0.4s ease;
}

.slider::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 18px;
  height: 18px;
  background-color: #a38569;
  transition: all 0.4s ease;
}

.switch input[type="checkbox"]:checked + .slider {
  background-color: #09ff099d;
}

.switch input[type="checkbox"]:checked + .slider::before {
  transform: translateX(24px);
}

/* Round */
.round {
  border-radius: 34px;
}

.round::before {
  border-radius: 50%;
}
