.permissions-table {
  .table-wrapper {
    .title {
      font-size: 20px;
      font-family: "Nunito Sans";
      span {
        font-family: "Nunito Sans-bold";
        text-transform: capitalize;
      }
    }
    td {
      .custom-checkbox {
        margin: unset;
      }
    }
  }
  .search-wrap {
    .cta-col {
      .btn {
        background-color: #0b586d;
      }
    }
  }
  .save-col {
    text-align: end;
    .btn {
      background-color: #0b586d;
    }
  }
}
