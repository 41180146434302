@import "../../styles/TextStyles.scss";
.custom-textarea {
  label {
    font-family: $font-bold;
    font-size: 15px;
    color: #1d1d1d;
    margin-bottom: 13px;
    width: 100%;
    .count {
      float: right;
      color: #A7A7A7;
      font-size: 16px;
    }
  }
  .input-group {
    .form-control {
      padding: 20px 10px;
      color: #000;
      border-radius: 5px;
      box-shadow: 0px 3px 6px #8282821a;

      &:focus,
      &:hover {
        border-color: #0b586d;
      }
    }
  }
}
