@import "../../styles/TextStyles.scss";

.images {
  height: 100% !important;
  height: inherit;

  .custom-panel {
    margin-top: 25px;

    .heading {
      padding-top: 10px;

      .title {
        display: inline-block;
        font-size: 18px;
        margin: 0;
        line-height: 1;
      }
    }

    .image-input {
      margin-bottom: 15px;

      label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        color: #333;
        font-weight: 700;
      }

      input {
        border: 1px solid #ccc;
        width: 100%;
        padding: 6px 12px;
      }
    }

    .current-image {
      @media screen and (min-width: 992px) {
        float: left;
      }

      label {
        display: block;
        margin-bottom: 5px;
        color: #333;
        font-weight: 700;
      }

      img {
        max-width: 358px;
      }
    }

    .selected-image-wrapper {
      position: relative;
      max-width: 358px;

      .delete-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(246, 246, 246, 0.65);

        img {
          cursor: pointer;
        }
      }

      .selected-image {
        width: 100%;
      }
    }

    .upload-msg {
      max-width: 100%;
      text-align: center;
      padding: 50px;
      font-size: 22px;
      color: #aaa;
      margin: 20px 0;

      @media screen and (min-width: 992px) {
        width: 50%;
        float: right;
      }

      div {
        max-width: inherit;
      }
    }

    .range-slider {
      width: 35%;
      margin-left: auto;
      text-align: center;
    }

    .btn-col {
      display: flex;
      justify-content: flex-end;

      .primary-button-container {
        width: auto;
        padding: 9px 30px 9px;
      }
    }
  }

  .heading-row {
    justify-content: space-between;

    .custom-input-search {
      .input-group {
        border-radius: 154px;
        height: 41px;

        .input-group-text {
          padding-top: unset;
        }
      }
    }

    .cta-col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media screen and (max-width: 480px) {
        margin-top: 20px;
      }
    }
  }

  .primary-button-container {
    width: 142px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
  }

  .image-container {
    display: flex;
    flex-direction: row;
    place-content: stretch;
    box-sizing: border-box;
    width: 100%;
    flex-wrap: wrap;
    padding: 20px;
  }

  .image {
    width: 100%;
    max-width: 25%;
    padding: 10px;
    position: relative;

    img {
      height: 300px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      object-fit: cover;
    }

    @media screen and (max-width: 1200px) {
      max-width: 33.33%;
    }

    @media screen and (max-width: 900px) {
      max-width: 50%;
    }

    @media screen and (max-width: 750px) {
      max-width: 100%;
    }

    .caption {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: #bfbfbf4f;
      font-weight: 600;
      text-align: center;
      padding: 4px 0;
    }

    .menu-button {
      width: calc(100% - 20px);
      background-color: #bfbfbf4f;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .three-dots {
        margin-right: 7px;
        width: 25px;
        height: 25px;
      }

      .edit-button {
        margin-right: 3px;
      }

      img {
        cursor: pointer;
      }
    }
  }

  .galleryPanel {
    .body {
      background-color: #ededed;
      padding: 0;
    }
    .image-input{
      background-color: white;
      padding: 20px !important;
    }
  }
}