@import "../../../styles/TextStyles.scss";
@import "../../../styles/Colors.scss";

.reset-pass {
  height: 100vh;
  .logo {
    text-align: center;
    display: block;
    margin: 20px auto;
  }
  .custom-card {
    max-width: 100%;
    padding: 30px;
    h2 {
      margin-bottom: 5px;
      font-size: 19px;
      font-family: $font-bold;
    }
    p {
      margin-top: 10px;
      font-family: $font-regular;
      color: #959595;
      font-size: 14px;
    }

    .forget-pass-link {
      padding-top: 12px;
      a {
        color: #629dd9;
        font-size: 15px;
        font-family: $font-semibold;
      }
    }

    .signup-link {
      margin-top: 26px;
      color: #959595;
      font-family: $font-semibold;
      font-size: 14px;
      a {
        color: #629dd9;
        font-size: 14px;
        font-family: $font-semibold;
      }
    }
  }
}
