@import "../../styles/TextStyles.scss";

.view-invoice {
  // width: 100%;
  // height: 100vh;
  // overflow-y: auto;
  padding-inline: 25px;
  padding-block: 25px;

  h2 {
    text-align: left;
    font-size: 22px;
    font-family: $font-bold;
    padding: 20px;
  }
  .back {
    cursor: pointer;
    width: 55px;
    height: 63px;
    padding: 20px;
    @media screen and (max-width: 480px) {
      display: block;
      margin-top: 20px;
    }
  }

  .text-start {
    justify-content: space-between;
    align-items: center;
    flex-wrap: unset;
    margin-bottom: 15px;
  }
  .center {
    text-align: center;
    h4 {
      font-family: $font-bold;
    }
  }
  .add-data {
    text-align: end;
    font-family: $font-bold;
    .name {
      color: #dc3545;
    }
  }

  .number-date {
    display: flex;
    justify-content: space-between;
    padding-inline: 25px;
    .date {
      font-family: $font-bold;
      &:last-child {
        text-align: end;
        text-transform: capitalize;
      }
    }
    .number {
      font-family: $font-bold;
      &:last-child {
        text-align: end;
      }
    }
    .value {
      text-align: end;
    }
    .date-value {
      text-align: end;
    }
  }

  .h-line {
    border-top: 1px solid #4e4742;
  }

  .table {
    thead {
      tr {
        th {
          background-color: #4e4742;
          color: #ffffff;
          border: 3px solid #f6f6f6;
          padding: 10px;
          text-align: center;
          vertical-align: top;
        }
        .h-line {
          border-top: 1px solid #4e4742;
          width: 100%;
        }
      }
    }
    tbody {
      tr {
        border-top: 4px solid #f6f6f6;
        td {
          // font-family: $font-regular;
          background-color: #dadde18c;
          border: 3px solid #f6f6f6;
          padding: 10px;
          &:nth-child(2) {
            text-align: center;
          }
          &:nth-child(3) {
            text-align: center;
          }
          &:last-child {
            text-align: end;
          }
        }
      }
    }
  }

  .table-2 {
    tbody {
      tr {
        td:nth-child(2) {
          text-align: end;
        }
      }
    }
  }
  .payment-mode {
    margin-bottom: 15px;
    .col {
      display: flex;
      justify-content: center;
      div {
        &:first-child {
          font-family: $font-bold;
        }
      }
    }
  }

  .invoice-block {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 20px;
    .name {
      font-size: 22px;
      font-family: $font-bold;
      align-items: center;
      text-align: center;
      color: #000000;
      opacity: 1;
    }
    .location {
      font-size: 15px;
      font-family: $font-regular;
      align-items: center;
      text-align: center;
      color: #382d2d;
      opacity: 1;
    }
    .sub-name {
      font-size: 14px;
      font-family: $font-regular;
      align-items: center;
      text-align: center;
      color: #b1b1b1;
      opacity: 1;
    }
    .total-item {
      font-size: 14px;
      font-family: $font-regular;
      color: #959595;
      padding-left: 15px;
      padding-top: 15px;
      border-top: 1px solid #8282821a;
      margin-top: 20px;
    }
    .item {
      padding-bottom: 15px;
      border-bottom: 1px solid #8282821a;
      .item-list .item-name {
        font-size: 16px;
        font-family: $font-semibold;
        color: #0b586d;
        text-align: left;
      }
      .item-list .item-description {
        font-size: 14px;
        font-family: $font-semibold;
        color: #959595;
      }
      .product-amount .discount-price {
        font-size: 15px;
        font-family: $font-semibold;
        text-align: end;
        color: #000000;
      }
      .product-amount .regular-price {
        font-size: 15px;
        font-family: $font-regular;
        text-decoration: line-through;
        color: #b1b1b1;
        text-align: end;
      }
      .duration {
        text-align: end;
        margin-top: 8px;
      }
    }

    .calculation {
      margin-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #8282821a;
      flex-wrap: unset;
      .calculation-heading .subtotal {
        font-size: 15px;
        font-family: $font-semibold;
        color: #111111;
      }
      .calculation-heading .tax {
        font-size: 15px;
        font-family: $font-semibold;
        color: #111111;
      }
      .calculation-amount .subtotal-amount {
        font-size: 15px;
        font-family: $font-semibold;
        text-align: end;
        color: #000000;
      }
      .calculation-amount .tax-amount {
        font-size: 15px;
        font-family: $font-semibold;
        text-align: end;
        color: #000000;
      }
    }

    .total {
      margin-top: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #8282821a;
      .total-heading {
        font-size: 15px;
        font-family: $font-semibold;
        color: #111111;
      }
      .total-amount {
        font-size: 15px;
        font-family: $font-semibold;
        text-align: end;
      }
    }

    .cash {
      margin-top: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #8282821a;
      .cash-heading .cash-name {
        font-size: 15px;
        font-family: $font-semibold;
        color: #111111;
        text-transform: capitalize;
      }
      .cash-heading .cash-date {
        font-size: 14px;
        font-family: $font-semibold;
        color: #b1b1b1;
      }
      .cash-amount .cash-amount-dummy {
        font-size: 15px;
        font-family: $font-regular;
        color: #fff;
        text-align: end;
      }
      .cash-amount .cash-amount-value {
        font-size: 15px;
        font-family: $font-semibold;
        text-align: end;
        color: #000000;
      }
    }

    .balance {
      margin-top: 15px;
      padding-bottom: 10px;
      .balance-heading .balance-name {
        font-size: 15px;
        font-family: $font-semibold;
        color: #111111;
      }
      .balance-amount .balance-amount-value {
        font-size: 15px;
        font-family: $font-semibold;
        text-align: end;
        color: #000000;
      }
    }

    .appointment-timeline {
      margin-top: 30px;

      .heading {
        font-family: $font-extrabold;
        font-size: 17px;
        margin-bottom: 20px;
      }
      ul {
        position: relative;
        list-style: none;
        li {
          position: relative;
          padding-left: 30px;
          padding-bottom: 15px;
          &:last-child {
            &::after {
              display: none;
            }
          }
          &::before {
            content: " ";
            width: 10px;
            height: 10px;
            position: absolute;
            top: 7px;
            left: 10px;
            border-radius: 100%;
            background: #0b586d;
          }
          &::after {
            content: " ";
            position: absolute;
            left: 14px;
            border-left: 1px solid #8282821a;
            top: 5px;
            bottom: 0px;
          }
          &.two {
            &::before {
              background: #5a95a5;
            }
          }
          &.three {
            &::before {
              background: #a4c1c9;
            }
          }
          &.four {
            &::before {
              background: #d7dbdc;
            }
          }
        }
      }
    }
  }
  .invoice-panel {
    padding: 0px;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border-left: 1px solid #8282821a;
    .client-details {
      padding: 10px 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #8282821a;
      img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: 27px;
      }
      .email-cont {
        label {
          font-size: 14px;
          font-family: $font-semibold;
          margin: 0px;
          color: #000000;
        }
        .value {
          font-size: 15px;
          color: #000;
        }
      }
    }
    .status {
      background: #f6f6f6;
      padding: 15px 20px;
      .dropdown {
        width: 250px;
        margin: 0 auto;
        button {
          width: 250px;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
    .invoice {
      height: 100%;
      position: relative;
      overflow: hidden;
      .container-fluid {
        height: 100%;
        .center-box {
          justify-content: center;
          align-items: center;
          height: 60%;
          .completed {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 35px;
            width: 30%;
          }
          .no-invoice {
            text-align: center;
            font-size: 16px;
            font-family: $font-semibold;
            color: #1a1a1a;
          }
        }
        .send-invoice-text {
          padding-left: 15px;
        }
        .bottom-box-save {
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #8282821a;
          .button-style .save-btn {
            height: 57px;
            border-radius: 5px;
            background-color: #0b586d;
            align-items: center;
            align-content: center;
            vertical-align: middle;
            line-height: 57px;
            // padding: 15px 30px 15px;
            margin-bottom: 11px;
            transition: all 0.2s ease-in;

            &:hover {
              cursor: pointer;
              box-shadow: 0 4px 12px -3px #0b586d;
            }

            .button-text {
              color: #ffffff;
              font-size: 15px;
              font-family: $font-semibold;
              text-align: center;
            }
          }
        }
        .bottom-box-appoint {
          justify-content: center;
          align-items: center;
          margin-top: 25px;
        }
      }
    }
  }
}
