@import "../../../styles/TextStyles.scss";
.staff-listing {
  .cta-row {
    padding-top: 30px;
    .custom-dropdown {
      width: 194px;
      .css-6iiga6-container {
        height: 54px;
      }
      &::active {
        height: 54px;
      }
    }
  }
  .date-control-div {
    padding-left: 15px;
    .today-btn{
      background-color: white;
      height: 55px;
      margin-left: 10px;
      border: 1px solid #e6e6e6;
      padding: 0px 10px;
      font-size: 15px;
      font-weight: normal;
      color: grey;
      &:hover{
        border: 1px solid #b9b6b6 !important;
      }
      &:focus{
        outline: none !important;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }
  }
  .staff-options {
    margin-left: 15px;
    .css-q4ifmu-control {
      box-shadow: unset !important;
    }
  }
  .date-control {
    display: flex;
    flex-direction: row;
    .previous-date,
    .next-date {
      padding: 15px 19px;
      font-size: 15px;
      background-color: #ffffff;
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      cursor: pointer;
    }
    .picker {
      margin: 0 10px;
      .react-datepicker-wrapper {
        input {
          padding: 15px 19px;
          font-size: 15px;
          background-color: #ffffff;
          border: 1px solid #e6e6e6;
          border-radius: 3px;
          color: #6d6d6d;
        }
      }
    }
  }
  .staff-table {
    margin-top: 25px;
    cursor: pointer;
    .table {
      color: #000000;
      thead {
        background: #fff;
        border-radius: 10px;
        overflow: hidden;

        tr {
          th {
            border: none;
            padding: 15px;
            font-size: 15px;
            font-family: $font-bold;
          }
        }
      }
      tbody {
        tr {
          border-top: 10px solid #f6f6f6;

          td {
            border: none;
            padding: 15px;
            font-size: 14px;
            background: #fff;
            color: #818181;
            font-family: $font-semibold;
            .time-slot {
              background-color: rgba(#0b586d, 0.1);
              color: #000000;
              display: flex;
              font-family: $font-semibold;
              font-size: 14px;
              padding: 5px 20px;
              width: fit-content;
            }
          }
          .close-date {
            background-color: rgba(#6d6d6d, 0.1);
          }
          .add-symbol {
            span {
              font-size: 21px;
              visibility: hidden;
              background-color: unset;
              margin-right: auto;
            }
            &:hover {
              span {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  .custom-dropdown > div {
    height: 57px;
  }
}
.delete-repeating-shift-backdrop {
  z-index: 1050;
  opacity: 0.6 !important;
}
.font-bold {
  font-weight: 600;
}
.delete-shift-footer {
  justify-content: space-between;
  div {
    button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
.modal-content {
  .custom-dropdown {
    .dropdown-menu {
      overflow-y: hidden;
    }
  }
}
