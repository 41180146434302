@import "../../../styles/TextStyles.scss";
.my-business {
  .heading {
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 19px;
      font-family: $font-bold;
    }
    .step-count {
      font-size: 19px;
      color: #747474;
    }
  }
  .my-business-form {
    .custom-panel {
      margin-bottom: 17px;
      font-size: 16px;
      font-family: $font-semibold;
      .copy-link {
        input {
          background-color: rgb(247, 245, 245);
          border: unset;
        }
        span {
          border-radius: 0px;
          background-color: #088abf;
          color: #fff;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        // button {
        //   height: 40px;
        //   border-radius: unset;
        //   overflow: hidden;
        //   text-overflow: ellipsis;
        //   white-space: nowrap;
        //   background-color: blue;
        //   border-top-right-radius: 4px;
        //   border-bottom-right-radius: 4px;
        // }
      }
      .title {
        font-size: 16px;
        font-family: $font-bold;
        margin-bottom: 11px;
      }
      .staff-select {
        .custom-checkbox {
          margin: 0;
          margin-bottom: 30px;
        }
        .image-object {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;
          img {
            width: 47px;
            height: 47px;
            object-fit: cover;
            border-radius: 100%;
            margin-right: 25px;
          }
          .content {
            font-size: 16px;
            font-family: $font-bold;
          }
        }
      }
      .day-row {
        align-items: center;
        padding: 10px 0;
        .custom-dropdown button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 767px) {
            margin-bottom: 10px;
          }
        }
        .day-off-checbox {
          margin: 0;
          .form-check {
            justify-content: center;
          }
        }
      }
    }
    .basic-info {
      .profile-pic-label {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 100%;
        &:hover {
          .overlay {
            opacity: 1;
          }
        }

        .profile-pic {
          width: 120px;
          height: 120px;
          border-radius: 100%;
          object-fit: cover;
          padding: 2px;
          border: 1px solid #0b586d;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(#0b586d, 0.5);
          border-radius: 100%;
          opacity: 0;
        }
        input {
          visibility: hidden;
          display: none;
        }
      }
      // .custom-dropdown {
      // margin-bottom: 24px;
      // }
      .mb {
        .col-md-6 {
          margin-bottom: 15px;
        }
      }
      .checkbox-col {
        display: flex;
        align-items: center;
        // margin-bottom: 24px !important;
        .checkbox-block {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          label {
            margin-bottom: 0;
            margin-right: 15px;
          }
          .checkbox-wrapper {
            display: flex;
            flex-wrap: wrap;
            .checkbox-label {
              div {
                margin: 0;
              }
              &:first-child {
                margin-right: 15px;
              }
            }
          }
        }
      }
      .align-self-center {
        label {
          margin-bottom: 1rem;
        }
      }
      .custom-dropdown {
        button {
          &:disabled {
            color: #6d6d6d;
            cursor: not-allowed;
          }
        }
      }
    }
    .address-container {
      .checkbox-col {
        display: flex;
        align-items: center;
        .checkbox-block {
          display: flex;
          label {
            margin-right: 15px;
          }
          .checkbox-label {
            div {
              margin: 0;
            }
          }
        }
      }
      .mb {
        .col-md-6 {
          margin-bottom: 15px;
        }
      }
    }
    .working-hour {
      .col-md-12 {
        .row-1 {
          div {
            @media screen and (max-width: 767px) {
              margin-bottom: 10px;
            }
          }
        }
        .day-row {
          .col-md-2 {
            @media screen and (max-width: 767px) {
              margin-bottom: 10px;
            }
          }
          .col-md-4 {
            div {
              @media screen and (max-width: 767px) {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
    .search-location {
      margin: 0 !important;
      left: calc(50% - 160px);
      @media (min-width: 769px) {
        min-width: 320px;
      }
      @media screen and (max-width: 768px) {
        left: 3% !important;
        min-width: 250px !important;
        top: 15% !important;
      }
      @media screen and (max-width: 576px) {
        min-width: 192px !important;
      }
    }
    .custom-dropdown > div {
      height: 57px !important;
    }
  }
  .cursorChange {
    cursor: pointer;
    padding: 0px;
  }
  .custom-dropdown {
    .css-6iiga6-container {
      height: 57px;
    }
    &::active {
      height: 57px;
    }
  }
  .custom-dropdown > div {
    height: 57px;
  }
}
.warning {
  span {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fcf3cd;
    align-items: center;
    color: #000000;
    padding: 8px 16px;
    margin-top: 5px;
    border-radius: 3px;
  }
  a {
    color: #000000;
    // padding: 2px 6px;
    border-radius: 8px;
    text-decoration: underline;
    font-family: $font-bold;
  }
}
