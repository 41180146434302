@import  "../../styles/TextStyles.scss";

.pagination-component {
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  margin: 0 !important;
  margin-top: 10px !important;
  .result-text {
    color: #6d6d6d;
    font-size: 15px;
    @media screen and (max-width: 480px) {
      text-align: center;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
  .count {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: flex-end;
    @media screen and (max-width: 480px) {
      justify-content: center;
    }
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .previous,
    .next {
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d9d9d9;
      border-radius: 100%;
    }
    .previous {
      margin-right: 20px;
      @media (max-width: 400px) {
        margin-right: 5px;
      }
    }
    .next {
      margin-left: 20px;
      @media (max-width: 400px) {
        margin-left: 5px;
      }
    }
    .pages {
      height: 40px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d9d9d9;
      border-radius: 30px;
      font-family: $font-semibold;
      font-size: 14px;
      color: #989898;
      padding: 6px 15px;

      justify-content: space-evenly;
      span {
        vertical-align: middle;
        padding: 6px 10px 9px 10px;
        &.active {
          color: #ffffff;
          background: #0b586d;
          border-radius: 5px;
        }
      }
    }
  }
}