@import "../../../styles/TextStyles.scss";

.seller-form {
  padding: 30px 20px;

  .heading {
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 19px;
      font-family: $font-bold;
    }

    .step-count {
      font-size: 19px;
      color: #747474;
    }
  }

  .nav-tabs {
    margin: 0 !important;
  }

  .nav-item {
    // margin-bottom: unset !important;
    background-color: #ededed;
    border: 1px solid #d7d7d7;
  }

  //company-info
  .company-info {
    border: 1px solid lightgrey;
    padding: 30px 30px;
    background-color: white;

    .logo-cont {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .profile-pic-label {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 100%;

        &:hover {
          .overlay {
            opacity: 1;
          }
        }

        .profile-pic {
          width: 120px;
          height: 120px;
          border-radius: 100%;
          object-fit: cover;
          padding: 2px;
          border: 1px solid #0b586d;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(#0b586d, 0.5);
          border-radius: 100%;
          opacity: 0;
        }

        input {
          visibility: hidden;
          display: none;
        }
      }
    }

    .copy-link {
      input {
        // background-color: rgb(247, 245, 245);
        border: 1px solid #bdbdbd;
      }

      span {
        border-radius: 0px;
        background-color: #088abf;
        color: #fff;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .custom-input {
      margin-bottom: 0px !important;

      .input-group {
        margin-top: 5px !important;
      }
    }

    .custom-dropdown {
      .form-label {
        margin-bottom: 5px !important;
      }
    }

    .custom-dropdown>div {
      height: 57px !important;
    }

    .dropdown-toggle {
      border: 1px solid #ced4da;
    }

    .dropdown-toggle:hover {
      border: 1px solid #0b586d;
    }

    .arrow-icons {
      transform: rotate(180deg);
    }
  }

  //administrators-info
  .administrators-info {
    border: 1px solid lightgrey;
    padding: 30px 30px;
    background-color: white;
  }

  //add-bulk-cont
  .add-bulk-cont {
    border: 1px solid lightgrey;
    padding: 30px 30px;
    background-color: white;

    .select-file-cont {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .customers-file-upload {
      position: absolute;
      z-index: 99999;
      opacity: 0;
      width: 150px;
      height: 50px;
    }
  }

  //Company groups
  .groups {
    border: 1px solid lightgrey;
    padding: 30px 30px;
    background-color: white;
  }
}