@import "../../../styles/TextStyles.scss";
.danger-button-container {
  border-radius: 5px;
  background-color: #de0c0c;
  padding: 14px 30px 14px;
  transition: all 0.2s ease-in;
  border-color: #de0c0c;

  max-height: 52px;
  &:hover,
  &:disabled,
  &:focus,
  &:active {
    background-color: #de0c0c !important;
    border-color: #de0c0c !important;
  }
  &:hover,
  &:focus {
    box-shadow: 0 4px 12px -3px #de0c0c !important;
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    box-shadow: unset !important;
  }
  .button-text {
    color: #ffffff;
    font-size: 15px;
    font-family: $font-semibold;
    text-align: center;
  }
}
