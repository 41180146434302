
@import '../../../styles/TextStyles.scss';
.custom-checkbox{
   margin-top:30px;
   input{
       width: 20px;
       height: 20px;
       box-shadow: 0px 3px 6px #70707029;
       border: 1px solid #E8E8E8;
       border-radius: 3px;
       margin-top: 2px;
      
   }  
   label{
    margin-left: 12px;
    color: #000000;
    font-size: 15px;
    a {
        color: #629dd9;
        font-size: 15px;
        font-family: $font-semibold;
      }
   }
}