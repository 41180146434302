@import "../../../styles/TextStyles.scss";

.grossTransactionsReport {
  .no-data-text {
    text-align: center;
  }

  .filtersCont {
    margin: 0;

    .primary-button-container {
      margin-top: 40px !important;

      @media (max-width: 576px) {
        margin-top: 1px !important;
      }
    }
  }

  .mainContent {
    margin: 0px;
    margin-top: 20px;
    

    .topRowCont {
      margin: 0;

      .headingCont {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin-right: 0px !important;
        background-color: transparent !important;

        h4 {
          font-size: 25px;
          font-family: $font-extrabold;

          @media (max-width: 992px) {
            font-size: 20px;
            font-family: $font-extrabold;
          }
        }

        p {
          font-size: 15px;
          font-family: $font-bold;


        }

        @media (max-width: 992px) {
          h4 {
            font-size: 20px;
          }

          p {
            font-size: 12px;
          }
        }
      }

      .box-cont {
        background-color: black;
        padding: 0;
        padding: 5px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          align-items: normal;
        }

        .box-content {
          width: 100%;
          height: 100%;
          padding: 0;
          background-color: #8d7551;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 10px;
          color: white;
          // border-radius: 10px;

          .num {
            font-size: 30px;
            font-family: $font-extrabold;

            @media (max-width: 992px) {
              font-size: 20px;
            }
          }

          .text {
            font-size: 15px;
            font-family: $font-extrabold;
            padding-left: 15px;
          }

          @media (max-width: 768px) {
            margin-bottom: 15px;
          }
        }

        .box1 {
          background-color: grey;
        }

        .box2 {
          // background-color: #0070BC;
          background-color: #01AF50;
        }

        .box3 {
          background-color: #01AF50;
        }
        .box4 {
          background-color: #A6A6A6;
        }
        .box5 {
          background-color: #A6A6A6;
        }

        .discount-box{
          background-color: #01AF50;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          p{
            padding: 0 !important;
          }
          span{
            font-size: 15px;
            // font-family: $font-bold;
          }
        }

      }
    }

    .horizontal-bar0graph-cont {
      .multiGraphContent {
        width: 100%;
        height: 400px;
        background-color: #dadada;
        margin-top: 15px;
        border-radius: 10px;
      }

      .barGraphContent {
        .bottomSecContent {
          width: 100%;
          height: 400px;
          background-color: #dadada;
          margin-top: 15px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 10px;

          p {
            font-size: 15px;
            font-family: $font-extrabold;
          }

          .barGraphCont {
            height: 50%;
            width: 100%;

            @media (max-width: 992px) {
              width: 180px;
            }
          }
        }
      }
    }

    .graphsCont {
      .multiGraphContent {
        width: 100%;
        height: 200px;
        background-color: #dadada;
        margin-top: 15px;
        border-radius: 10px;
        padding: 20px;


      }

      .voucherGraphContent {
        width: 100%;
        height: 400px;
        background-color: #dadada;
        margin-top: 15px;
        border-radius: 10px;
        padding: 20px;


      }


    }
  }

  .discount-codes-cont{
    // margin: 0px 15px 0px 15px;
    .discount-codes-content{
    background-color: #c8c5c5;
    padding: 0px 10px 15px 10px;
    border-radius: 10px;

      .graphsCont{
        // margin: 0;
      }
    }
  }

  .and-ios-data-cont {
    display: flex;
    @media (max-width: 500px) {
      flex-direction: column;
    }

    .headings-cont {
      width: fit-content;
      @media (max-width: 500px) {
        width: 100%;
      }
      .headings-content {
        width: 100%;
        height: fit-content;
        border: 1px solid grey;
        @media (max-width: 500px) {
          flex-direction: column;
        }
        p {
          border: 1px solid grey;
          font-size: 25px;
          font-family: $font-extrabold;
          display: flex;
          justify-content: center;
          align-items: center;
        }

      }
    }

    .data-cont {
      width: 100%;

      .data-content {
        display: flex;
        justify-content: space-evenly;

        @media (max-width: 1100px) {
          display: grid;
          grid-template-columns: repeat(8, minmax(0px, 1fr));
        }
        @media (max-width: 992px) {
          display: grid;
          grid-template-columns: repeat(5, minmax(0px, 1fr));
        }
        @media (max-width: 768px) {
          display: grid;
          grid-template-columns: repeat(3, minmax(0px, 1fr));
        }
        @media (max-width: 576px) {
          display: grid;
          grid-template-columns: repeat(1, minmax(0px, 1fr));
        }

        .data-item-cont {

          border: 1px solid grey;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 80px;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid grey;
            width: 100%;
            height: 80px;
          }

          .left-cont {
            font-size: 20px;
            font-family: $font-extrabold;
            background-color: #d9d9d9;
          }

          .right-cont {
            background-color: #e8e8e8;
            flex-direction: column;
            font-size: 16px;
            font-family: $font-bold;

            p {
              border: 1px solid grey;
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
            }
          }
        }




      }
    }
  }

}