@import "../../../../styles/TextStyles.scss";
.add-single-service-form {
  margin-top: 37px;
  .basic-info {
    .col-md-7 {
      .custom-dropdown,
      .custom-input {
        margin-bottom: 15px;
      }
    }
  }
  .custom-panel {
    margin-bottom: 17px;
    font-size: 16px;
    font-family: $font-semibold;
    .title {
      font-size: 16px;
      font-family: $font-bold;
      margin-bottom: 11px;
    }
    .user-input-wrap {
      .user-input {
        // display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
    .input-dropdown-button {
      .btn {
        height: 57px;
        box-shadow: 0px 3px 6px #8282821a;
      }
    }
    @media (max-width: 768px) {
      .input-dropdown-button {
        flex-basis: 100%;
      }
    }

    .user-input {
      padding: 0;
    }
    .staff-select {
      .custom-checkbox {
        margin: 0;
        margin-bottom: 30px;
      }
      .members-row {
        max-height: 500px;
        overflow-y: auto;
      }
      .image-object {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        img {
          width: 47px;
          height: 47px;
          object-fit: cover;
          border-radius: 100%;
          margin-right: 25px;
        }
        .content {
          font-size: 16px;
          font-family: $font-bold;
        }
      }
    }
  }
  .pricing-container {
    // .pricing-card {
    //   background-color: #f2f2f7;
    // }
    span {
      color: #0000ffba;
      cursor: pointer;
    }
    .price-heading {
      display: flex;
      justify-content: space-between;
      img {
        width: 18px;
        cursor: pointer;
      }
    }
    .mt {
      margin-top: unset;
      .col-md-6 {
        margin-top: 15px;
      }
    }
  }
  .services {
    display: inline-flex;
  }
  .custom-dropdown {
    .css-6iiga6-container {
      height: 57px;
    }
    &::active {
      height: 57px;
    }
  }
  .custom-dropdown > div {
    height: 57px !important;
  }
}
