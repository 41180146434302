@import "../../../../styles//TextStyles.scss";

.sale-details {
  padding: 0px 30px 30px 30px;

  .header-cont {
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      padding: 10px 10px;
      min-width: 150px;
      max-width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p{
      font-size: 18px;
      font-family: $font-extrabold;
    }
  }

  .header {
    font-family: "Nunito Sans-bold";
    font-size: 14px;
  }

  .card-cont {
    width: 100%;
    p {
      overflow: hidden;
    }
  }

  .footer-cont {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

}