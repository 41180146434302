.bookings {
  padding-top: 30px;
  .heading-row {
    .custom-dropdown {
      .btn {
        height: 57px;
      }
    }
    .cta-col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-right: 15px;
      @media screen and (max-width: 480px) {
        margin-top: 20px;
      }
      .primary-button-container {
        width: 142px;
        border-radius: 7px;
      }
    }
  }
  .paid-status-cont{
    text-wrap: nowrap;
  }
    .table-cont {
      .ref-no-cont {
        cursor: pointer;
        &:hover{
          text-decoration: underline;
          color: blue;
        }
      }
      .markPaidCont{
        button{
          padding: 5px !important;
        }
      }
    }
}
