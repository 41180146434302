@import '../../styles/TextStyles.scss';

.table-component {
  min-height: fit-content;
  max-height: fit-content;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: grey;
  }

  .table-responsive{
    &::-webkit-scrollbar {
      height: 5px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: grey;
      border-radius: 10px;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: grey;
    }
  }

  .table {
    tr {
      background-color: white;

      th {
        vertical-align: middle;
        font-size: 14px;
        font-family: $font-bold;
        padding: 20px 10px;
        white-space: nowrap;
      }

      td {
        color: #5d5d5d;
        vertical-align: middle;
        padding: 10px 10px;
        background: transparent !important;
        font-size: 14px;
        font-family: $font-semibold;
      }
    }
  }
}