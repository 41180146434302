@import "../../styles/TextStyles.scss";
.notication {
  width: 584px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 37px 99px #a5a5a529;
  border-radius: 9px 9px 9px 9px;
  z-index: 99;
  padding: 15px;
  // transform: translateX(100%);
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 480px) {
    // width: auto;
  }
  &.show {
    // transform: translateX(0);
    display: flex;
    flex-direction: column;
    z-index: 100;
    .tab-content {
      height: calc(100% - 44px);
      overflow-y: auto;
    }
  }
  .gear {
    position: absolute;
    top: 52px;
    right: 30px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .nav-tabs {
    border: none;
    align-items: flex-end;
    .nav-link {
      color: #bbbbbb;
      font-size: 23px;
      font-family: $font-bold;
      padding: 8px 0px;
      margin-right: 50px;
      transition: all 0.3s ease-in;
      margin-bottom: 10px;
      &:hover {
        border: none;
        box-shadow: none;
        outline: none;
      }
      &.active {
        color: #000000;
        font-size: 23px;
        border: none;
        border-bottom: 5px solid #0b586d;
      }
    }
  }
  .tab-content::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  .tab-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  /* Handle */
  .tab-content::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
  }
  /* Handle on hover */
  .tab-content::-webkit-scrollbar-thumb:hover {
    background: grey; 
  }
  .notification-list {
    list-style: none;
    margin-right: 16px;
    padding-bottom: 100px;
    li {
      margin-bottom: 25px;
      &.day {
        font-family: $font-bold;
        font-size: 17px;
        color: #b2b2b2;
        text-transform: capitalize;
      }
      .media-object {
        cursor: pointer;
        border-bottom: 1px solid #ececec;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 10px 8px;
        img {
          width: 45px;
          height: 45px;
          border-radius: 100%;
          object-fit: cover;
          margin-right: 20px;
        }
        .details {
          flex-grow: 1;
          .title {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            h3 {
              font-size: 14px;
              color: #000000;
            }
            .time {
              color: #aeaeae;
              font-size: 14px;
              font-family: $font-bold;
            }
          }
          .sub-title {
            color: #b1b1b1;
            font-size: 14px;
            font-family: $font-semibold;
          }
        }
      }
      .unseen-notification {
        background-color: #ececec;
      }
    }
  }
  .view-all{
    text-align: right;
    padding-right: 10px;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
  }
}
.notification-popup {
  width: 584px;
  height: 100vh;
  box-shadow: unset !important;
  // margin-top: 36px;
}
