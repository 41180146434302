.keywords-table {
  margin-top: 10px;
  max-width: 100%;
  .table-wrapper {
    .search-wrap {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .custom-input-search {
        padding: 10px;
      }
      .cta-col {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-right: 16px;
        @media screen and (max-width: 480px) {
          margin-top: 15px !important;
        }
        .primary-button-container {
          width: 142px;
          border-radius: 7px;
        }
      }
    }
    .table-responsive{
      padding: 10px;
    }
    .table-cont{
      padding: 20px 10px 15px 15px;
      .profile-pic {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
        margin-right: 20px;
        border: 1px solid #0b586d;
      }
    }
  }
}
.footer {
  .save-btn {
    background-color: #0b586d;
  }
}
.image-wrapper{
  margin-top: 20px;
  padding: 0;
  img{
    margin-top: 20px;
    width: 400px;
    height: 300px;
  }
}


.add-fav-salon-cont{
  .dropdown-menu{
    max-height: 300px !important;
    overflow-y: scroll !important;
    width: 100%;
  }
}
