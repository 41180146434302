.client {
  margin-top: 25px;
  padding: unset;
  @media screen and (max-width: 480px) {
    padding: unset;
  }
  .heading-row {
    justify-content: space-between;
    .custom-input-search {
      padding: 10px;
      .input-group {
        border-radius: 154px;
      }
    }
    .cta-col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-right: 28px;
      @media screen and (max-width: 480px) {
        margin-top: 15px;
      }
      .primary-button-container {
        width: 142px;
        border-radius: 7px;
      }
      .dropdown {
        margin-right: 17px;
      }
    }
  }
}
