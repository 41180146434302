@import "../../../styles/TextStyles.scss";
.custom-checkbox {
  margin-top: 10px;
  .form-check {
    display: flex;
    align-items: center;
    input {
      width: 20px;
      height: 20px;
      box-shadow: 0px 3px 6px #70707029;
      border: 1px solid #e8e8e8;
      border-radius: 3px;
      margin-top: 2px;
      cursor: pointer;
    }
    label {
      margin-left: 12px;
      color: #1d1d1d;
      font-size: 15px;
      font-family: $font-bold;
      cursor: pointer;
      a {
        color: #629dd9;
        font-size: 15px;
        font-family: $font-semibold;
      }
    }
  }
}
