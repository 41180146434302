@import "../../../styles/TextStyles.scss";

.add-client {
  h2 {
    text-align: center;
    font-size: 29px;
    font-family: $font-bold;
    background-color: #fff;
    padding: 20px;
  }
  .form-cont {
    margin-top: 80px;

    .profile-pic-label {
      position: relative;
      width: 120px;
      height: 120px;
      border-radius: 100%;
      border: 1px solid #0b586d;
      display: flex;
      justify-content: center;
      align-items: center;
      .profile-pic {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        object-fit: cover;
        padding: 3px;
        // border: 1px solid #0b586d;
      }
      input {
        visibility: hidden;
        display: none;
      }
    }
    .mb {
      .col-md-6,
      .col-md-12 {
        margin-bottom: 15px;
      }
    }

    .vr {
      margin: 0 auto;
      width: 1px;
      height: 100%;
      border-left: 1px solid #8282821a;
    }
    .right-section {
      hr {
        margin: 0px;
        border: none;
        border-bottom: 1px solid #8282821a;
        margin-bottom: 20px;
      }
      .address-row {
        border-radius: 3px;
        max-height: 500px;
        overflow-y: auto;
        .custom-card {
          width: 100%;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          border: 1px solid #e0e0e0;
          .address-data {
            padding: 10px 20px;
          }
          .icons-wrapper {
            padding: 10px;
            img {
              cursor: pointer;
              max-width: 15px;
              margin-left: 15px;
            }
          }
        }
      }
    }
    .save-client {
      padding-top: 10px;
      padding-bottom: 20px;
    }
    .custom-dropdown {
      .css-6iiga6-container {
        height: 57px;
      }
      &::active {
        height: 57px;
      }
    }
  }
  .custom-dropdown > div {
    height: 57px !important;
  }

  .assign-groups{
    .css-6iiga6-container{
      height: fit-content !important;
    }
    .custom-dropdown .custom-dropdown-select__indicator-separator{
      top: 3px !important;
    }
  }
}
