.full-screen-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  overflow-y: auto;
  z-index: 999;
  .cancel {
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 30px;
    top: 37px;
    z-index: 5;
  }
}
