@import "../../../styles/TextStyles.scss";

.closed-dates {
  .cta-row {
    padding-top: 30px;
    justify-content: flex-end;
    margin-left: auto;
    .new-closed-date-button {
      margin-left: auto !important;
      width: 191px;
      border-radius: 7px;
    }
  }

  .closed-dates-table {
    margin-top: 25px;
    .table {
      color: #000000;
      thead {
        background: #fff;
        border-radius: 10px;
        overflow: hidden;

        tr {
          th {
            border: none;
            padding: 28px 20px;
            font-size: 15px;
            font-family: $font-bold;
          }
        }
      }
      tbody {
        tr {
          border-top: 10px solid #f6f6f6;
          cursor: pointer;
          td {
            border: none;
            padding: 20px 20px;
            font-size: 14px;
            background: #fff;
            color: #818181;
            font-family: $font-semibold;
            .time-slot {
              background-color: rgba(#0b586d, 0.1);
              color: #000000;
              font-family: $font-semibold;
              font-size: 14px;
              padding: 5px 20px;
            }
          }
        }
      }
    }
  }
}

.closed-dates-popup {
  .container-fluid {
    padding: 0;
    .inst-text {
      margin: 0px 0 10px;
      background: rgba(#0b586d, 0.13);
      padding: 22px 30px;
      font-size: 15px;
      border-radius: 7px;
      color: #616161;
    }
    .user-input {
      padding: 0;
    }
    .bottom {
      margin-top: 19px;
    }
  }
  .display-cb-cont{
    .label-cont{
      height: 35px;
      display: block;
      @media (max-width: 768px) {
        display:none;
      }
    }
    .checkbox-label {
      height: 57px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .custom-checkbox {
        margin: unset;
        padding-right: 15px;
      }
      .form-label{
        margin: unset;
      }
      .info-ico-cont{
        width: 15px;
        height: 15px;
      }
    }
  }
}
.modal-content {
  .react-datepicker {
    background-color: #ffffff;
    border: unset;
    box-shadow: 2px 3px 4px 6px rgba(#545e61, 0.13);
    .react-datepicker__header {
      background-color: white;
      border-bottom: unset;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      border-radius: 1.3rem;
      background-color: #000000;
      color: #fff;
    }
    .react-datepicker__day--selected {
      background-color: black;
      border-radius: 1.3rem;
    }
  }
  box-sizing: border-box;
  .react-datepicker-wrapper {
    &:before {
      content: "";
      position: absolute;
      right: 35px;
      top: 55px;
      z-index: 10000;
      width: 10px;
      height: 10px;
      border: 2px solid #a4a4a4;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      border-radius: 1px solid #fff;
      transform: rotate(-45deg);
      transition: 0.5s;
      pointer-events: none;
    }
  }

  .close {
    font-size: 45px;
    font-weight: lighter;
    line-height: 0.5;
    color: black;
    text-shadow: 0 1px 0 #fff;
    opacity: 2.5;
    &:focus {
      outline: unset !important;
    }
  }

  .modal-footer {
    .secondary-button-container {
      border-radius: 7px;
      margin-right: 15px !important;
    }
    .primary-button-container {
      border-radius: 7px;
    }
    padding: 15px 32px !important;
  }
}
