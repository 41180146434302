@import "../../../styles/TextStyles.scss";
.dash-card {
  box-shadow: 0px 1px 5px #9f9f9f29;
  border-radius: 7px;
  min-height: 150px;
  padding: 20px;
  height: 100%;
  @media screen and (max-width: 480px) {
    margin-bottom: 20px;
  }

  .icon-cont {
    width: 30px;
    height: 30px;
    padding: 8px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    img {
      width: 14px;
      height: 14px;
    }
  }
  .count {
    word-break: break-word;
    margin-top: 13px;
    font-size: 28px;
    font-family: $font-bold;
    .sub {
      font-size: 12px;
    }
  }
  p {
    font-size: 13px;
    font-family: $font-bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
