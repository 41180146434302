@import "../../../../styles/TextStyles.scss";
.member-edit-card {
  padding: 20px;
  text-align: center;
  position: relative;
  .profile-image {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    padding: 3px;
    border: 1px solid #eee;
    object-fit: cover;
  }
  .caption {
    font-size: 12px;
    font-style: italic;
    color: #8d8d8d;
    margin-top: 5px;
  }
  .name {
    font-size: 19px;
    color: #000;
    font-family: $font-bold;
    margin-top: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .desig {
    font-size: 15px;
    color: #8d8d8d;
  }

  .email {
    border-radius: 3px;
    border: none;
    margin-top: 22px;
    overflow: hidden;
    background-color: rgba(11, 88, 109, 0.1);
    border-radius: 3px;
    width: 30px;
    height: 20px;
    border: none;
    overflow: hidden;
    padding: 6px;
    box-sizing: initial;
  }

  .edit {
    width: 25px;
    position: absolute;
    top: 0px;
    right: 5px;

    .three-dots {
      margin-right: 7px;
      width: 25px;
      height: 25px;
    }
    .edit-button {
      margin-right: 3px;
    }
    img {
      cursor: pointer;
    }
  }
}
.remove-selected-item {
  // width: 200px;
  // height: 100px;
  box-shadow: 0px 1px 10px #a7a7a740;
  .popover-body {
    padding: 15px 15px;
  }
}
.staff-menu-popup {
  width: 193px;
  height: 122px;
  box-shadow: 0px 1px 10px #a7a7a740;
}
