.access-denied {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
  cursor: pointer;

  &__content {
    text-align: center;
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &__icon {
      font-size: 3rem;
      color: #888;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 2rem;
      color: #333;
      margin-bottom: 10px;
    }

    &__message {
      font-size: 1rem;
      color: #666;
      margin-bottom: 20px;
      line-height: 1.5;
    }

    &__button {
      background-color: #28a745;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;

      &:hover {
        background-color: #218838;
      }
    }
  }
}
